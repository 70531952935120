import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { LoginssoComponent } from './loginsso/loginsso.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { LoaderComponent } from './_shared/loader/loader.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HttpClientModule } from '@angular/common/http';
import { httpInterceptorProviders } from './http-interceptors';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Claim, UpdateClaim } from '../app/_shared/models/claim.model';
import { NgxEditorModule } from 'ngx-editor';
import { CustomerDetailsViewComponent } from './_shared/components/customer-details-view/customer-details-view.component';
import { CustomerDetailsViewSaxComponent } from './_shared/components/customer-details-view-sax/customer-details-view-sax.component';
import { PayoutTransactionsComponent } from './_shared/components/payout-transactions/payout-transactions.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { DisplayDocComponent } from './agent/display-doc/display-doc.component';
import { DisplayDocSaxComponent } from './agent/display-doc-sax/display-doc-sax.component';
import { PdfViewerModule,PdfViewerComponent } from 'ng2-pdf-viewer';
import { InboundCustomerViewComponent } from './_shared/components/inbound-customer-view/inbound-customer-view.component';
import { DocterDetailsAddition } from './_shared/components/docter-details-new-add/docter-details-new-add.component';
import { CSATComponent } from './csat/csat.component';
import { OpenCasesDetailsViewComponent } from './_shared/components/open-cases-view/open-cases-view.component';
import {OpenAgentCasesDetailsViewComponent} from './_shared/components/agent-cases-view/agent-cases-view.component';


// import { DisplayDocComponent }
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginssoComponent,
    ResetpasswordComponent,
    LoaderComponent,
    CustomerDetailsViewComponent,
    CustomerDetailsViewSaxComponent,
    PayoutTransactionsComponent,
    UploadDocumentsComponent,
    DisplayDocComponent,
    DisplayDocSaxComponent,
    InboundCustomerViewComponent,
    DocterDetailsAddition,
    CSATComponent,
    OpenCasesDetailsViewComponent,
    OpenAgentCasesDetailsViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
    NgxEditorModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PdfViewerModule    
  ],
  entryComponents:[CustomerDetailsViewComponent, CustomerDetailsViewSaxComponent, PayoutTransactionsComponent],
  providers: [httpInterceptorProviders, Claim, UpdateClaim,PdfViewerComponent],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class AppModule { }