import { EnvironmentConfig } from './i.config';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: EnvironmentConfig = {
  production: false,
  
  // webURL: 'http://localhost:4200',
  // apiURL: 'http://localhost:3000/api/',
  // saxURL: 'http://localhost:3000/sax/',
  // authURL: 'http://localhost:3000/auth/',
  // ezURL: 'http://localhost:3000/',

  webURL: 'https://eztravel.europassistance.in',
  apiURL: 'https://eztravelapi2.europassistance.in/api/',
  saxURL: 'https://eztravelapi2.europassistance.in/sax/',
  authURL: 'https://eztravelapi2.europassistance.in/auth/',
  ezURL: 'https://eztravelapi2.europassistance.in/',

  ssoURL: 'https://api.entrustuat.in/',

  apiEndPoints: {
    login: 'users/login',
    createUser: 'users/create',
    getRoles: 'roles/list',
    generateJwt: 'users/generateCustomerJWT',
    getDetailsByemail: 'users/getuserbyEmail',
    getClientLogo: 'api/application/pagesOfRole',
    getClientName: 'insuranceProviders/getByUUID',
    getCsatStatus:'csatScore/getCsatSubmitStatus',
    saveScore:'csatScore/saveCsatScore',
    saveNpsScore:'csatScore/updateCsatScore',
    claimSearch: 'claims/search',
    claimSearchByClient: 'claims/searchbyclient',
    updateInboundCustomer:'inboundCustomers/customersUpdate',
    claimHistory: 'claims/claimHistory',
    customerPolicySearch: 'customers/search',
    customerInboundPolicySearch:'inboundCustomers/getCustomerByCertNo',
    customerInboundNewPolicySearch:'inboundCustomers/getCustomersBySearch',
    insuranceProviders: 'insuranceproviders',
    InboundInsuranceProviders:'inbound/getInboundMasters',
    getDoctors:'docterDetails/listDocterDetails',
    policySearch: 'policy/search',
    searchToAssign: 'policy/searchToAssign',
    countries: 'countries',
    intimateClaimsData: 'intimateClaims',
    claimDetailsFromClient: 'claimDetailsFromClients',
    addcountry: 'countries/add',
    updatecountry: 'countries/update',
    deletecountry: 'countries/delete',
    addstate: 'countries/state/add',
    deletestate: 'countries/state/delete',
    todaydate: 'todaydate',
    customerDetails: 'customers/getCustomerDetails',
    saveClaim: 'cases/saveclaim',
    saveClaimFromEclaim: 'eclaims/cases/saveClaim',
    uploadMultipleDocs: 'uploadDocuments/multiple',
    claimDetails: 'cases/getCaseDetails',
    fileTrackerById: 'fileTrackingData',
    saveFileTrackerData: 'createFileTrackingData',
    updateFileTrackerData: 'updateFileTrackingData',
    getAllRGICDocuments: 'getAllRGICDocuments',
    getDocumentByteStreamRGIC: 'getDocumentByteStreamRGIC',
    getRGICPolicyCopy: 'getRGICPolicyCopy',
    getRGICommunicationLetter: 'getRGICCommunciationLetter',
    updateClaim: 'cases/updateClaim',
    agentCase: 'agentCount',
    updateClaimStatusWithLog: 'cases/updateClaimStatusAfterLog',
    uploadDocument: 'uploadDocuments',
    testExample:'uploadDocumentsInbound',
    travelCaseUpdateDoc: 'travelCases/updateDocuments',
    saveLogs: 'travelCases/saveLogs',
    saveLogsNewCollection: 'travelCases/saveLogsNewCollection',
    saveInboundLog:'travelCases/saveInboundLog',
    updatelogIdByClaim: 'travelCases/logListIdUpdateByCase',
    updatelogIdById: 'travelCases/logListIdUpdateByID',
    saveNote: 'travelCases/createNote',
    customerClaimHistory:'inbound/inboundClaimHistory',
    saveNewNote: 'cases/updateNote',
    saveInboundNewNote:'cases/saveinboundClaimNote',
    getNewNote: 'cases/getClaimNote',
    getInboundNewNote:'cases/getInboundClaimNote',
    getLogs: 'travelCases/getLogs',
    getLogByIdAll: 'travelCases/getLogByIdAll',
    getInboundLogs:'travelCases/inboundLogs',
    getNotes: 'travelCases/getNotes',
    getToEmails: 'masters/getClaimTypesbyClient',
    clientWiseMasterData: 'masters/getClaimTypesbyClientMultiple',
    getFields: 'getfields',
    saveRawData: 'portRaw/create',
    sendPaymentLink: 'inbound/makePayment',
    lastCustomerId: 'customers/getlastcustomerId',
    lastCaseId: 'cases/lastCaseId',
    uploadFileTrackerData: 'uploadDocumentsNeww',
    downloadFileForFileTracker:'fileTrackerHistoryReport',
    getFileTrackerData:'historyTracker',
    getAllCases:'travelAlert/getCasesForAssignment',
    fileTrackerImageUpload: 'uploadDocumentsImages',
    uploadBulkData: 'customers/uploadBulkDataNew',
    removeBulkData: 'customers/removeBulkCustomers',
    uploadBulkDataCases: 'customers/uploadBulkDataCases',
    saxDataUpload: 'saxData/bulkSaxData',
    mchiTravelDataUpload: 'crondatas/bulkMchiTravelData',
    getMchiTravelData: 'mchiTravelData/getMchiTravelData',
    currencyConvertor: 'convertCurrency',
    saveCAS: 'travelCases/saveCas',
    savePayable: 'travelCases/savePayable',
    savePayableArray: 'travelCases/savePayableArray',    
    saveCustomer: 'customers/create',
    inboundCustomer:'inboundCustomers/create',
    inboundCustomers:'inboundCustomers/getCustomerDetails',
    saveInboundClaim:'inbound/saveInboundCase',
    updateInboundClaim:'inbound/updateInboundCase',
    createCustomerAssignPolicy: 'customers/createCustomerAssignPolicy',
    updateCustomerName: 'customers/updateCusName',
    updateInboundCustomerName: 'inboundCustomers/updateCusNameInbound',
    updateDateofLossfromService: 'travelcases/updateDateofLossfromService',
    createClaimWhenDelayHappens: 'createClaimWhenDelayHappens',
    makePaymentsIG: 'flightTracking/updateStatus',
    makePaymentsforAllClaims: 'IG/flightTrackingDetails/listAll',
    makePaymentsforAllPennyDrops: 'IG/flightTrackingDetails/listAllPennyDrop',
    flightRemainders: 'flightRemainders',
    getIGDelayClaim: 'getClaims',

    getIGDelayClaimsByStatus: 'IG/getClaimsByStatus',
    
    getSMSEmail: 'IG/getSmsMails',
    getpolicyInstance: 'getPolicyInstanceByPolicy',

    IGlistPennyDrops: 'IG/listPennyDrops',
    IGlistPaidClaims: 'IG/listPaidClaims',

    IGTransactions: 'IG/getTransactios',

    listAllSenderChannels:"listAllSenderChannels",
    createChannel:"createSenderChannels",
    updateChannelByClientID:"updateSenderChannelsByClientID",
    getLogByType: "travelsLog/getLogByType",
    deleteChannel:"deleteSenderChannels",
    updateCustomerWithPolicy: 'customers/update',
    filePath: 'travelCases/getDocuments?file=',
    getClientSchemes: 'schemes/getClientSchemes',
    getDocumentsList: "documents/list",
    deleteDocument: "travelCases/deleteDocuments",
    deleteInboundDocument: "inboundCases/deleteDocumentsInbound",
    getNextFlowStatus: "workFlow/flowStatus",
    updateClaimStatus: "UpdateClaimStatus",
    getCustomers: "customers/list",
    getPolicyById: "policy/getpolicybyid",
    getClaimAlerts: "travelAlert/claimAlerts",
    getInboundClaimAlerts: "inbound/getInboundCases",
    getCustomerByCaseNumber:'inbound/getInboundCasesByCaseNumber',
    getClaimDetailsById:'inbound/getInboundCasesByPolicy',
    getCustomerDataFromClient: 'getTataAigFlightDetails',
    getCronData: "crondatas/getCronData",
    getCronDataById: "crondatas/getCronDataById",
    getInvalidClaimAlerts: "travelAlert/invalidclaimAlerts",
    getRejectedClaimAlerts: "travelAlert/rejectedclaimAlerts",
    travelAlertsSearch: "travelAlert/search",
    rateConvertor: "rate/convert",
    newrateConvertor: "rate/newconvert",
    customerWithClaimDetails: "customers/getCustomerWithClaims",
    getAndUpdateVB64: "getPolicyDetailsFromRGIC",
    claimLogs: "getClaimLogs",
    inboundClaimLogs:'inboundCases/getClaimLogs',
    getUsersList: "users/list",
    getUsersListByDept: "users/listbydept",
    getRoleDetailsById: "getRoleDetails",
    UpdatePermissions: "updatePermissions",
    UpdateUser: "users/update",
    UpdateUserPass: "users/updatepass",
    getUserDetails: "getUserDetails",
    UpdateDocumentStatus: "documents/updateStatus",
    saveProvider: "insuranceProviders/create",
    saveInboundProvider:'inbound/addInboundMasters',
    createDoctor:'docterDetails/createDocterDetails',
    insuranceProvidersUpdate: "insuranceProviders/update",
    InboundInsuranceProvidersUpdate:"inbound/updateInboundMaster",
    updateDoctor:'docterDetails/updateDocterDetails',
    searchDocter: 'docterDetails/getDocterDetails',
    createPolicy: "policySchemes/createPolicy",
    updatePolicy : "policy/updatePolicy",
    updatePlan : "policyScheme/updatePlan",
    createPlan: "createPlan",
    casesReport: 'downloadCasesReport',
    getClaimDetails:'travelCases/getServiceLogs',
    saveClaimDetails:'travelCases/saveService',
    saveInboundClaimDetails:'inboundCases/saveInboundService',
    deleteClaimDetails:'travelCases/deleteService',
    deleteInboundClaimDetails:'inboundCases/deleteInboundService',
    updateClaimDetails:'travelCases/updateService',
    updateInboundClaimDetails:'inboundCases/updateInboundService',
    getpayouttransactions: 'payouttransactions/getDataByCaseNumber',
    createpayouttransactions: 'payouttransactions/create',
    updatepayouttransactions: 'payouttransactions/update',
    deletepayouttransactions: 'payouttransactions/delete',
    updatePayable:'travelCases/updatePayable',    
    getSchedularJob: 'scheduled-jobs/getFrequency',
    createcommunication:'CreateCommunication',
    getCommunication:'listCommunication',
    updateCommunicationByClientID:'updateCommunication',
    deleteCommunication:'deleteCommunication',
    getTemplate:"listAllTemplate",
    createTemplate:"createTemplate",
    createSchedularJob: 'schedued-jobs/createfrequency',
    updateSchedularJob: 'schedued-jobs/updateFrequency',
    deleteSchedularJob: 'schedued-jobs/deletefrequency',

    getemailtemplates: 'emailtemplates/getemailtemplates',
    createemailtemplates: 'emailtemplates/createemailtemplates',
    createTemplateNote: 'templatesNotes/createTemplateNote',
    getTemplateNotes:'templatesNotes/list',
    deleteTemplateNoteById:'templatesNotes/deleteTemplateNoteById',
    getManualTemplate:'templatesNotes/getManualTemplate',
    getManualTemplateForClaimProcess:'templatesNotes/getManualTemplateForClaimProcess',
    saveClaimProcessSheet:'claimProcessSheet/saveClaimProcessSheet',
    updateClaimProcessSheet:'claimProcessSheet/updateClaimProcessSheet',
    getClaimProcessSheet:'claimProcessSheet/getClaimProcessSheet',
    getCsatTemplate:'travelCases/getTemplateForCsat',
    updateTemplateNote:'templatesNotes/updateTemplateNote',
    updateemailtemplates: 'emailtemplates/updateemailtemplates',
    deleteemailtemplates: 'emailtemplates/deleteemailtemplates',

    getsmstemplates: 'smstemplates/getsmstemplates',
    createsmstemplates: 'smstemplates/createsmstemplates',
    updatesmstemplates: 'smstemplates/updatesmstemplates',
    deletesmstemplates: 'smstemplates/deletesmstemplates',

    getwhatapptemplates: 'whatsapptemplates/getwhatsapptemplates',
    createwhatapptemplates: 'whatsapptemplates/createwhatsapptemplates',
    updatewhatapptemplates: 'whatsapptemplates/updatewhatsapptemplates',
    deletewhatapptemplates: 'whatsapptemplates/deletewhatsapptemplates',

    updateTemplateByClientID:"updateTemplateByClientID",
    deleteTemplate:"deleteTemplate", 
    
    reAssingmentCases: 'travelAlert/getReAssignmentCases',
    agentAssingedCases: 'agentCases',
    agentAssingedCasesPerPages: 'agentCasesPerPage',
    
    getRuleEngineFlightDelay: 'ruleengineinstgrat/getFlightDelayList',
    createRuleEngineFlightDelay: 'ruleengineinstgrat/createFlightDelay',  
    validateRuleEngine: 'forRuleEngineValididations',
    validateRuleEngineforCancellation: 'forRuleEngineCancellationValididations',
    updateSRuleEngineFlightDelay: 'ruleengineinstgrat/updateFlightDelay',
    deleteRuleEngineFlightDelay: 'ruleengineinstgrat/deleteFlightDelay',

    getRuleEngineFlightCancel: 'ruleengineinstgrat/getFlightCancelList',
    createRuleEngineFlightCancel: 'ruleengineinstgrat/createFlightCancel',    
    updateSRuleEngineFlightCancel: 'ruleengineinstgrat/updateFlightCancel',
    deleteRuleEngineFlightCancel: 'ruleengineinstgrat/deleteFlightCancel',

    getFlightDelays: 'ruleengineinstgrat/getPayments',
    getFlightCancels: 'ruleengineinstgrat/getCancelTicketsPayments',

    saveTask: 'taskList/sendtask',
    saveInboundTask: 'inboundTaskList/sendtask',
    updateTask: 'taskList/updatetask',    
    updateInboundTask: 'inboundTaskList/updatetask',    
    tasksAssigned: 'taskList/tasksAssigned',
    tasksInboundAssigned: 'inboundTaskList/tasksAssigned',
    myTasks: 'taskList/myTasks',
    myPendingTasks: 'taskList/myPendingTasks',    
    myNoofPendingTasks: 'taskList/myNoofPendingTasks',
    allTasks: 'taskList/allTasks',
    totalTasks: 'taskList/totalTasks',
    totalInboundTasks: 'inboundTaskList/totalTasks',
    allTasksSearchList: 'taskList/allTasksSearchList',
    allInboundTasksSearchList: 'inboundTaskList/allTasksSearchList',
    tasklistDownload: 'taskList/tasklistDownload',
    tasklistDownloadPending: 'taskList/tasklistDownloadPending',
    tasklistDownloadCompleted: 'taskList/tasklistDownloadCompleted',
    logsReportsDownload:'travelCases/getLogReport',
    fileTrackerFailureReport:'getFileTrackingFailureData',
    getFileTrackingData:'getFileTrackingData',
    getLogWiseData:'travelCases/logWiseReport',
    getEclaimReport:'travelCases/eclaimReport',
    tasksById: 'taskList/taskById',
    getTasksByCaseNumber: 'taskList/getTasksByCaseNumber',
    getInboundTasksByCaseNumber: 'inboundTaskList/getTasksByCaseNumber',
    getDatesasPerLogs: 'travelCases/getLogsByWithoutComment',
    getGeneralMasters: "masters/getGeneralMasters",
    getEclaimsGeneralMasters: "masters/getEclaimsGeneralMasters",
    addGeneralMasters: "masters/addGeneralMasters",
    addEclaimsGeneralMasters: "masters/addEclaimsGeneralMasters",
    checkdocument:"travelCases/markedDocument",
    checkLogRecord:"travelCases/markedLogRecord",
    checkLogRecordNew:"travelCases/updateLogClientView",
    updateGeneralMaster: "masters/updateGeneralMaster",
    updateEclaimsGeneralMaster: "masters/updateEclaimsGeneralMaster",
    getClaimDetailsXl: "travelCases/getClaimDetailsXl",
    getClaimDetailsNew: "travelCases/getClaimDetails_New",
    getInboundCasesReports:'inbound/inboundCasesReport',
    careAtHomeReport:'inbound/careAtHomeReport',
    getInboundReports:'inbound/inboundReport',
    getCSATReports:'csat/csatReport',
    getFinanceReport: 'inbound/getPayUReport',
    getMISreport: "travelCases/getMISReport",
    getDebitNoteReport: "travelCases/getDebitNoteReport",
    getDebitNoteReportAdityaBirla : "travelCases/getDebitNoteReportAdityaBirla",
    getDefiiencyReport: "travelCases/getDefficiencyReport",
    searchGeneralMaster: "masters/searchGeneralMaster",
    searchEclaimsGeneralMaster:'masters/searchEclaimsGeneralMaster',
    getLogComment: "travelsLog/getLogComment",
    getInboundLogComment:'travelsLog/saveInboundLogComment',
    getStates: "countries/getStates",
    getMasters: "masters/getMasters",
    getMastersAll: "masters/getMasterDataMultiple",
    getMastersClientWise: "masters/getClaimTypesbyClient",
    downloadAllLogForPdf: "travelsLog/getLogCommentForPdf",
    getReport:"travelCases/getReport",

    getCasesReport: 'travelCases/getCasesReport',
    getNOCReports: 'travelCases/getNOCReports',
    getMisceleneousReport: 'travelCases/getMisceleneousReport',
    getMisceleneousClaimReport: 'travelCases/getMisceleneousClaimReport',
    getInvoiceReport: 'travelCases/getInvoiceReport',
    updateCurrentClaimStatus: 'travelCases/updateClaimStatus',

    getDailyNOCReport: 'travelCases/getDailyNOCReport',
    getClaimIntimationReport: 'travelCases/getClaimIntimationReport_new',
    getClaimIntimationReportFull: 'travelCases/getClaimIntimationReport_first',
    getClaimIntimationReport_sax: 'travelAlert/generalReport',
    getMiscellaneousReport: 'cases/miscellaneousReports',
    getTaskListReport: 'travelCases/getTaskListReport',
    getCheckedDocument: 'travelCases/getCheckedDocument',
    getCaseDocuments: "cases/caseDocuments",
    getDocuments:"documents/getDocumentsList",
    getDocumentsForSubclaims:"documentsBasedonSubclaims",
    saveDocumentsList:"documents/saveDocumentsList",
    saveCaseDocs:"cases/addDocumentToCase",
    removeCaseDocs:"cases/removeDocumentToCase",    
    addCommentToDoc:"cases/addCommentToDoc",    
    addCaseDocumentDeficiency:"cases/addCaseDocumentDeficiency",    
    updateDocumentComments: "updateDocumentComments",
    updateDocumentsList:"documents/updateDocumentsList",
    deleteItemFromDocumentsList: "documents/deleteItemFromDocumentsList",
    saveDocumentClaimType: "documents/saveDocumentClaimType",
    updateDocumentClaimType: "documents/updateDocumentClaimType",
    deleteDocumentClaimType: "documents/deleteDocumentClaimType",
    changepassword: 'users/changepassword_n',
    ifscdetails: 'communicate/checkIfsc',
    sendMail: 'communicate/sendEmails_new',
    sendEmailFromClaims:'communicate/sendEmail_FromClaims',
    sendSmsAndMail: 'communicate/sendOtpSmsEmail',
    saveeclaimsbutton: 'saveeclaimsbutton',
    geteclaimsbuttonlist: 'geteclaimsbuttonlist',
    geteclaimsbuttonlistByClient: 'geteclaimsbuttonlistByClient',
    deleteclaimsbuttonlistByClient: 'deleteclaimsbuttonlistByClient',
    deleteInboundClient: 'inbound/deleteInboundMaster',
    deleteDoctor:'docterDetails/deleteDocterDetails',
    updateclaimsbuttonlistByClient: 'updateclaimsbuttonlistByClient',
    oAuthValidation: 'api/auth/oauth2/validateToken',
    getServerKey: 'api/auth/getServerKey',
    getEzToken: 'batchJobs/getClaintToken',
    getUserSSODetails: 'api/users/ssoUser',
    getAllOpenCases: 'allOpenCases',
    getOpenCasesByPagination: 'newOpenCases',
    deleteClientDoc:'clientDoc/deleteClientDocs',
    uploadDoc:'uploadDocumentsTest',
    getClientDoclist: 'clientDoc/clientDocs',
    getCaseAssignmentReportData:'cases/caseAssignmentReport'
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
