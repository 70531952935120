import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService  {

  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) { }
  userData = null

  getUsers() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getUsersList).pipe(map(data => {
      this.userData = data
      return data;
    }));
  }

  getUsersByDept(id) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getUsersListByDept+"?dept="+id).pipe(map(data => {
      return data;
    }));
  }

  createUser(user){
    return this.http.post(this.baseUrl + environment.apiEndPoints.createUser,user).pipe(map(data=>{
      return data;
    }))
  }
changepassword(data){
  return this.http.post(this.baseUrl + environment.apiEndPoints.changepassword,data).pipe(map(data=>{
    return data;
  }))
}
  getRoles(){
    return this.http.get(this.baseUrl + environment.apiEndPoints.getRoles).pipe(map(data=>{
      return data;
    }))
  }

  getRoleDetailsById(id){
    return this.http.get(this.baseUrl + environment.apiEndPoints.getRoleDetailsById+"?id="+id).pipe(map(data=>{
      return data;
    }))
  }

  updatePermissions(roleDetails){
    return this.http.post(this.baseUrl + environment.apiEndPoints.UpdatePermissions,roleDetails).pipe(map(data=>{
      return data;
    }))
  }
  
  getUserDetailsById(id){
    return this.http.get(this.baseUrl + environment.apiEndPoints.getUserDetails+"?id="+id).pipe(map(data=>{
      return data;
    }))
  }

  updateUser(user){
    return this.http.post(this.baseUrl + environment.apiEndPoints.UpdateUser,user).pipe(map(data=>{
      return data;
    }))
  }

  UpdateUserPass(user){
    console.log("Came to service")
    return this.http.post(this.baseUrl + environment.apiEndPoints.UpdateUserPass,user).pipe(map(data=>{
      return data;
    }))
  }

  

  permissionsdata(){
    return JSON.parse(sessionStorage.getItem('role_permissions'));
  }

  getMasters(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getMasters, data).pipe(map(data => {
      return data;
    }));
  }
  
}
