import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

import * as FileSaver from 'file-saver';  
import * as XLSX from 'xlsx';  
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';  
const EXCEL_EXTENSION = '.xlsx';  

import { UpdateClaim, Claim, Cas, UpdateClaimForm, updatePayable } from '../_shared/models/claim.model'

@Injectable({
  providedIn: 'root'
})
export class SaxService {

  private baseUrl = environment.saxURL;
  constructor(private http: HttpClient) { }

  getInsuranceProviders() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.insuranceProviders).pipe(map(data => {
      return data;
    }));
  }

  getClaim(caseId: string) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.claimDetails + '?caseNumber=' + caseId).pipe(map(data => {
      return data;
    }));
  }

  getCaseDocuments(CaseNumber: String) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getCaseDocuments + '?CaseNumber=' + CaseNumber).pipe(map(data => {
      return data;
    }));
  }

  getLogs(page, itemsPerPage, travelCaseId) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getLogs+"?page="+page+"&itemsPerPage="+itemsPerPage+"&travelCaseId="+travelCaseId).pipe(map(data => {
      return data;
    }));
  }
  
  getInboundLogs(page, itemsPerPage, travelCaseId) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getInboundLogs+"?page="+page+"&itemsPerPage="+itemsPerPage+"&travelCaseId="+travelCaseId).pipe(map(data => {
      return data;
    }));
  }

  getClaimIntimationReport(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getClaimIntimationReport_sax, data).pipe(map(data => {
      return data;
    }));
  }

  getTasksByCaseNumber(id) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getTasksByCaseNumber, id).pipe(map(data => {
      return data;
    }));
  } 
  getClaimLogs(caseId:string){
    return this.http.get(this.baseUrl+environment.apiEndPoints.claimLogs+'?key='+caseId).pipe(map(data=>{
      return data;
    }))
  }
  getCustomerWithClaims(customerId: string) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.customerWithClaimDetails + '?customerId=' + customerId).pipe(map(data => {
      return data;
    }));
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {  
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);  
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };  
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });  
    this.saveAsExcelFile(excelBuffer, excelFileName);  
  }  
  private saveAsExcelFile(buffer: any, fileName: string): void {  
     const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});  
     FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);  
  }

  
  getCustomerDetails(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.inboundCustomers,data).pipe(map(data => {
      return data;
    }));
  }
  getCustomerDetailsById(id) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.inboundCustomers +`?customerId=${id}`).pipe(map(data => {
      return data;
    }));
  }
  getClaimDetailsById(id) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getClaimDetailsById +`?Pls_Customer_Id=${id}`).pipe(map(data => {
      return data;
    }));
  }
  
  getInboundInsuranceProviders() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.InboundInsuranceProviders).pipe(map(data => {
      return data;
    }));
  }
  getDoctors() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getDoctors).pipe(map(data => {
      return data;
    }));
  }
  getFields() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getFields).pipe(map(data => {
      return data;
    }));
  }
  uploadBulkData(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.uploadBulkData, data).pipe(map(data => {
      return data;
    }));
  }

  removeBulkData() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.removeBulkData).pipe(map(data => {
      return data;
    }));
  }

  uploadBulkDataCases(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.uploadBulkDataCases, data).pipe(map(data => {
      return data;
    }));
  }
  saxDataUpload(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saxDataUpload, data).pipe(map(data => {
      return data;
    }));
  }

  mchiTravelDataUpload(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.mchiTravelDataUpload, data).pipe(map(data => {
      return data;
    }));
  }

  saveRawData(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveRawData, data).pipe(map(data => {
      return data;
    }));
  }
  getLastCustomerId() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.lastCustomerId).pipe(map(data => {
      return data;
    }));
  }
  getLastCaseId(clientName) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.lastCaseId + '?clientName=' + clientName).pipe(map(data => {
      return data;
    }));
  }  
  fileTrackerUpload(file: File) {
    if (file != undefined) {
      let formData: FormData = new FormData();
      formData.append('imgUploader', file);      
      return this.http.post(this.baseUrl + environment.apiEndPoints.uploadFileTrackerData+'?docTypeId=FileTrakarCSV', formData)
        .pipe(map(doc => {
          return doc;
        }));
    }
    else {
      window.alert("Please add a document to upload")
    }
  }

  fileTrackerImageUpload(file:any) {
    if (file != undefined) {
      let formData: FormData = new FormData();
      // formData.append('imgUploader', file);  
      for(const files of file){
        formData.append('imgUploader',files)
      }       
      return this.http.post(this.baseUrl + environment.apiEndPoints.fileTrackerImageUpload, formData)
        .pipe(map(doc => {
          return doc;
        }));
    }
    else {
      window.alert("Please add a document to upload")
    }
  }
  // fileTrackerUpload(data) {
  //   return this.http.post(this.baseUrl + environment.apiEndPoints.uploadFileTrackerData, data).pipe(map(data => {
  //     return data;
  //   }));
  // }  
  getCountries() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.countries).pipe(map(data => {
      return data;
    }));
  }
  getClientSchemes(clientId: String) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getClientSchemes + '?clientId=' + clientId).pipe(map(data => {
      return data;
    }));
  }
  
  getDocumentsList(claimType: String) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getDocumentsList + '?claimtype=' + claimType).pipe(map(data => {
      return data;
    }));
  }
  getSchedularJob() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getSchedularJob).pipe(map(data => {
      return data;
    }));
  }
  getTemplate() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getTemplate).pipe(map(data => {
      return data;
    }));
  }
  getCommunication() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getCommunication).pipe(map(data => {
      return data;
    }));
  }
  getchannel() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.listAllSenderChannels).pipe(map(data => {
      return data;
    }));
  }
  updateSchedularJob(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateSchedularJob, data).pipe(map(data => {
      return data;
    }));
  }
  createcommunication(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createcommunication, data).pipe(map(data => {
      return data;
    }));
  }
  updateTemplateByClientID(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateTemplateByClientID, data).pipe(map(data=>{
      return data;
    }))
  }

  updateCommunicationByClientID(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateCommunicationByClientID, data).pipe(map(data=>{
      return data;
    }))
  }
  
  updateChannelByClientID(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateChannelByClientID, data).pipe(map(data=>{
      return data;
    }))
  }
  getLogByType(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getLogByType, data).pipe(map(data=>{
      return data;
    }))
  }
  getLogComment(id:any) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getLogComment + '?_id=' + id).pipe(map(data => {
      return data;
    }));
  }
  getStates(id:any) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getStates + '?CountryCode=' + id).pipe(map(data => {
      return data;
    }));
  }
  // getMasters: "masters/getMasters"
  getMasters(data) {
    // const queryParams = `?projectName=${data.projectName}&generalMasterName=${data.generalMasterName}`;

    return this.http.post(this.baseUrl + environment.apiEndPoints.getMasters, data).pipe(map(data => {
      return data;
    }));
  }
  getMastersAll(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getMastersAll, data).pipe(map(data => {
      return data;
    }));
  }  
  getMastersClientWise(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getMastersClientWise, data).pipe(map(data => {
      return data;
    }));
  }
  downloadAllLogForPdf(data) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.downloadAllLogForPdf+"?travelCaseId="+data).pipe(map(data => {
      return data;
    }));
  }

  getGeneralMasters() {
    //   let requestObject = {
    //     "projectName": "EzTravel",
    //     "generalMasterName": "status"
    // }
    // let projectName = data['projectName'] + '?projectName=' +projectName+'?generalMasterName=' +generalMasterName
    // let generalMasterName = data['generalMasterName']
    // const queryParams = `?projectName=${data.projectName}&generalMasterName=${data.generalMasterName}`;
    // console.log(queryParams,'API');

    return this.http.get(this.baseUrl + environment.apiEndPoints.getGeneralMasters).pipe(map(data => {
      return data;
    }));
  }
  getEclaimsGeneralMasters() {
    //   let requestObject = {
    //     "projectName": "EzTravel",
    //     "generalMasterName": "status"
    // }
    // let projectName = data['projectName'] + '?projectName=' +projectName+'?generalMasterName=' +generalMasterName
    // let generalMasterName = data['generalMasterName']
    // const queryParams = `?projectName=${data.projectName}&generalMasterName=${data.generalMasterName}`;
    // console.log(queryParams,'API');

    return this.http.get(this.baseUrl + environment.apiEndPoints.getEclaimsGeneralMasters).pipe(map(data => {
      return data;
    }));
  }

  getDatesasPerLogs(travelCaseId) {
      return this.http.get(this.baseUrl + environment.apiEndPoints.getDatesasPerLogs + '?travelCaseId=' + travelCaseId).pipe(map(data => {
      return data;
    }));
  }
  addGeneralMasters(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.addGeneralMasters, data).pipe(map(data => {
      return data;
    }));
  }
  addEclaimsGeneralMasters(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.addEclaimsGeneralMasters, data).pipe(map(data => {
      return data;
    }));
  }
  updateGeneralMaster(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateGeneralMaster, data).pipe(map(data => {
      return data;
    }));
  }
  updateEclaimsGeneralMaster(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateEclaimsGeneralMaster, data).pipe(map(data => {
      return data;
    }));
  }
  getClaimDetailsXl(data) {

    // JSON.stringify(data)
    // "clientName": "MCHA",
    //   "startDate" : "2021-03-04T12:46:32.000",
    //   "endDate" : "2021-03-16T12:36:22.000"
    // let clientDet = data['clientName']
    // let startDate = data['startDate']
    // let endDate = data['endDate']
    // return this.http.get(this.baseUrl + environment.apiEndPoints.getClaimDetailsXl+'/?clientName='+clientDet+'&startDate='+startDate+'&endDate='+endDate).pipe(map(data => {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getClaimDetailsXl, data).pipe(map(data => {
      return data;
    }));
  }
  getClaimDetailsNew(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getClaimDetailsNew, data).pipe(map(data => {
      return data;
    }));
  }
  getInboundCasesReports(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getInboundCasesReports, data).pipe(map(data => {
      return data;
    }));
  }
  getInboundReports(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getInboundReports, data).pipe(map(data => {
      return data;
    }));
  }
  getCSATReports(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getCSATReports,data).pipe(map(data => {
      return data;
    }));
  }

  getFinanceReport(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getFinanceReport,data).pipe(map(data => {
      return data;
    }));
  }
  

  getTaskListDownload(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.tasklistDownload, data).pipe(map(data => {
      return data;
    }));
  }

  getLogsReports(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.logsReportsDownload,data).pipe(map(data=>{
      return data;
    }))
  }
  getFileTrackerFailureReports(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.fileTrackerFailureReport,data).pipe(map(data=>{
      return data;
    }))
  }
  getFileTrackingData(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getFileTrackingData,data).pipe(map(data=>{
      return data;
    }))
    //https://eztraveluatapi.europassistance.in/api//getFileTrackingData
  }
  getLogWiseData(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getLogWiseData,data).pipe(map(data=>{
      return data;
    }))
  }
  getEclaimReport(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getEclaimReport,data).pipe(map(data=>{
      return data;
    }))
  }
  getTaskListPending(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.tasklistDownloadPending, data).pipe(map(data => {
      return data;
    }));
  }

  getTaskListCompleted(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.tasklistDownloadCompleted, data).pipe(map(data => {
      return data;
    }));
  }

  getMISReport(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getMISreport, data).pipe(map(data => {
      return data;
    }));
  }
  getDebitNoteReport(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getDebitNoteReport, data).pipe(map(data => {
      return data;
    }));
  }

  getDefiiencyReport(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getDefiiencyReport, data).pipe(map(data => {
      return data;
    }));
  }
  getDebitNoteReportAdityaBirla(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getDebitNoteReportAdityaBirla, data).pipe(map(data => {
      return data;
    }));
  }

  

  getMiscellaneousReport(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getMiscellaneousReport, data).pipe(map(data => {
      return data;
    }));
  }
  
  searchGeneralMaster(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.searchGeneralMaster, data).pipe(map(data => {
      return data;
    }));
  }
  searchEclaimsGeneralMaster(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.searchEclaimsGeneralMaster, data).pipe(map(data => {
      return data;
    }));
  }
  getReport(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getReport, data).pipe(map(data => {
      return data;
    }));
  }
  saveButtonsList(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveeclaimsbutton,data).pipe(map(data => {
      return data;
    }));
  }

  createSchedularJob(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createSchedularJob,data).pipe(map(data => {
      return data;
    }));
  }

  getEmailTemplates() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getemailtemplates).pipe(map(data => {
      return data;
    }));
  }
  getSMSTemplates() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getsmstemplates).pipe(map(data => {
      return data;
    }));
  }
  deleteTemplateNoteById(data) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.deleteTemplateNoteById+"?_id="+data).pipe(map(data => {
      return data;
    }));
  }
  getManualTemplate(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getManualTemplate,data).pipe(map(data => {
      return data;
    }));
  }
  getManualTemplateForClaimProcess(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getManualTemplateForClaimProcess,data).pipe(map(data => {
      return data;
    }));
  }
  saveClaimProcessSheet(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveClaimProcessSheet,data).pipe(map(data => {
      return data;
    }));
  }
  updateClaimProcessSheet(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateClaimProcessSheet,data).pipe(map(data => {
      return data;
    }));
  }
  getClaimProcessSheet(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getClaimProcessSheet,data).pipe(map(data => {
      return data;
    }));
  }

  getTemplateForCsat(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getCsatTemplate,data).pipe(map(data => {
      return data;
    }));
  }

  updateTemplateNote(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateTemplateNote,data).pipe(map(data => {
      return data;
    }));
  }

  createEmailTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createemailtemplates,data).pipe(map(data => {
      return data;
    }));
  }

  createSMSTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createsmstemplates,data).pipe(map(data => {
      return data;
    }));
  }

  createTemplateNote(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.createTemplateNote,data).pipe(map(data => {
      return data;
    }))
  }
  getTemplateNotes(){
    return this.http.get(this.baseUrl + environment.apiEndPoints.getTemplateNotes).pipe(map(data => {
      return data;
    }))
  }
  updateEmailTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateemailtemplates,data).pipe(map(data => {
      return data;
    }));
  }

  updateSMSTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updatesmstemplates,data).pipe(map(data => {
      return data;
    }));
  }

  deleteWmailTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteemailtemplates,data).pipe(map(data => {
      return data;
    }));
  }

  deleteSMSTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deletesmstemplates,data).pipe(map(data => {
      return data;
    }));
  }
  
  createTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createTemplate,data).pipe(map(data => {
      return data;
    }));
  }
  createChannel(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createChannel,data).pipe(map(data => {
      return data;
    }));
  }
  

  getRuleEngine() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getRuleEngineFlightDelay).pipe(map(data => {
      return data;
    }));
  }

  createRuleEngine(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createRuleEngineFlightDelay,data).pipe(map(data => {
      return data;
    }));
  }

  validateRuleEngine(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.validateRuleEngine,data).pipe(map(data => {
      return data;
    }));
  }

  validateRuleEngineCancellation(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.validateRuleEngineforCancellation,data).pipe(map(data => {
      return data;
    }));
  }

  

  updateRuleEngine(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateSRuleEngineFlightDelay,data).pipe(map(data => {
      return data;
    }));
  }

  deleteRuleEngine(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteRuleEngineFlightDelay,data).pipe(map(data => {
      return data;
    }));
  }


  getRuleEngineFlightCancel() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getRuleEngineFlightCancel).pipe(map(data => {
      return data;
    }));
  }

  createRuleEngineFlightCancel(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createRuleEngineFlightCancel,data).pipe(map(data => {
      return data;
    }));
  }

  updateSRuleEngineFlightCancel(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateSRuleEngineFlightCancel,data).pipe(map(data => {
      return data;
    }));
  }

  deleteRuleEngineFlightCancel(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteRuleEngineFlightCancel,data).pipe(map(data => {
      return data;
    }));
  }


  getButtonsList() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.geteclaimsbuttonlist).pipe(map(data => {
      return data;
    }));
  }
  updateclaimsbuttonlistByClient(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateclaimsbuttonlistByClient,data).pipe(map(data => {
      return data;
    }));
  }
  deleteclaimsbuttonlistByClient(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteclaimsbuttonlistByClient,data).pipe(map(data => {
      return data;
    }));
  }
  deleteInboundClient(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteInboundClient,data).pipe(map(data => {
      return data;
    }));
  }
  deleteDoctor(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteDoctor,data).pipe(map(data => {
      return data;
    }));
  }

  deleteSchedularJob(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteSchedularJob,data).pipe(map(data => {
      return data;
    }));
  }
  deleteTemplate(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteTemplate,data).pipe(map(data=>{
      return data;
    }))
  }
  deleteCommunication(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteCommunication,data).pipe(map(data=>{
      return data;
    }))
  }
  deleteChannel(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteChannel,data).pipe(map(data=>{
      return data;
    }))
  }
  DocumentsList(){
    return this.http.get(this.baseUrl + environment.apiEndPoints.getDocuments).pipe(map(data => {
      // console.log(data,"aaklj")
      return data;
    }));
  }
  DocumentsListForSubclaims(){
    return this.http.get(this.baseUrl + environment.apiEndPoints.getDocumentsForSubclaims).pipe(map(data => {
      // console.log(data,"aaklj")
      return data;
    }));
  }
  saveDocumentsList(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveDocumentsList,data).pipe(map(data => {
      return data;
    }));
  }
  saveCaseDocs(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveCaseDocs,data).pipe(map(data => {
      return data;
    }));
  }
  removeCaseDocs(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.removeCaseDocs,data).pipe(map(data => {
      return data;
    }));
  }
  addCommentToDoc(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.addCommentToDoc,data).pipe(map(data => {
      return data;
    }));
  }
  addCaseDocumentDeficiency(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.addCaseDocumentDeficiency,data).pipe(map(data => {
      return data;
    }));
  }
  updateDocumentComments(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateDocumentComments,data).pipe(map(data => {
      return data;
    }));
  }
  
      
  updateDocumentsList(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateDocumentsList,data).pipe(map(data => {
      return data;
    }));
  }
  deleteItemFromDocumentList(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteItemFromDocumentsList,data).pipe(map(data => {
      return data;
    }));
  }

  saveDocumentClaimType(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveDocumentClaimType,data).pipe(map(data => {
      return data;
    }));
  }

  saveCountry(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.addcountry,data).pipe(map(data => {
      return data;
    }));
  }
  updateCountry(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updatecountry,data).pipe(map(data => {
      return data;
    }));
  }

  deleteCountry(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deletecountry,data).pipe(map(data => {
      return data;
    }));
  }

  saveState(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.addstate,data).pipe(map(data => {
      return data;
    }));
  }

  deleteState(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deletestate,data).pipe(map(data => {
      return data;
    }));
  }
   
  updateDocumentClaimType(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateDocumentClaimType,data).pipe(map(data => {
      return data;
    }));
  }

  deleteDocumentClaimType(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteDocumentClaimType,data).pipe(map(data => {
      return data;
    }));
  }

  getPolicyIById(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getPolicyById, data).pipe(map(data => {
      return data;
    }));
  }

  updateCustomerName(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateCustomerName, data).pipe(map(data => {
      return data;
    }));
  }
  updateInboundCustomerName(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateInboundCustomerName, data).pipe(map(data => {
      return data;
    }));
  }

  updateDateofLossFromService(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateDateofLossfromService, data).pipe(map(data => {
      return data;
    }));
  }


  getIFSCDetails(ifsc: String) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.ifscdetails + '?ifsc=' + ifsc).pipe(map(data => {
      return data;
    }));
  }

  

  sendMessage(form) {
    // console.log('came to service');    
    // let url = `https://api.infobip.com/email/2/send`;

    // const httpOptions = {
    //   headers: new HttpHeaders({
    //     'Content-Type':  'application/json',
    //     'authorization': 'Basic RVVST1A6RUFAcGFzczEyMw=='
    //   })
    // };
    
    
    // let options =  new HttpHeaders()
    // options.append('Authorization', 'Basic RVVST1A6RUFAcGFzczEyMw==')
    
    // // let options = {
    // //   headers: new HttpHeaders({
        
    // //      'authorization': `Basic RVVST1A6RUFAcGFzczEyMw==`
    // //    })
    // // }

    // // let headers = new HttpHeaders();
    // // headers = headers.set('authorization', 'Basic RVVST1A6RUFAcGFzczEyMw==');

    // // console.log(form, 'form data');
    // // console.log(options, 'options');
    // return this.http.post(url, form, {headers: options}).pipe(map(data => {
    //   console.log(data, 'result....');
    //   return data;
    // }));
    return this.http.post(this.baseUrl + environment.apiEndPoints.sendMail, form).pipe(map(data => {
      return data;
    }));

    
    
  }
  sendClaimMail(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.sendEmailFromClaims, data).pipe(map(data=>{
       return data;
    }))
  }
  createClaimWhenDelayHappens(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createClaimWhenDelayHappens, data).pipe(map(data => {
      return data;
    }));
  }

  makePaymentsIG(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.makePaymentsIG, data).pipe(map(data => {
      return data;
    }));
  }

  makePaymentsforAllClaims() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.makePaymentsforAllClaims).pipe(map(data => {
      return data;
    }));
  }

  makePaymentsforAllPennyDrops() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.makePaymentsforAllPennyDrops).pipe(map(data => {
      return data;
    }));
  }

  

  

  flightRemainders() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.flightRemainders).pipe(map(data => {
      return data;
    }));
  }

  getIGDelayClaim() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getIGDelayClaim).pipe(map(data => {
      return data;
    }));
  }

  IGlistPennyDrops() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.IGlistPennyDrops).pipe(map(data => {
      return data;
    }));
  }

  IGlistPaidClaims() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.IGlistPaidClaims).pipe(map(data => {
      return data;
    }));
  }

  currencyConversion(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.currencyConvertor, data).pipe(map(data => {
      return data;
    }));
  }

  downloadFile(file){
    return this.http.get(`${file}`, {
        responseType : 'blob',
        headers:new HttpHeaders().append('Content-Type','application/json')
    });
}

updatelogIdById(id, logId) {
  return this.http.get(this.baseUrl + environment.apiEndPoints.updatelogIdById+"?_id="+id+"&logId="+logId).pipe(map(data => {
    return data;
  }));
}

// Instant Gratification Functions

getCustomerDataFromClient(page, itemsPerPage, flag) {
  return this.http.get(this.baseUrl + environment.apiEndPoints.getCustomerDataFromClient+"?page="+page+"&itemsPerPage="+itemsPerPage+"&flag="+flag).pipe(map(data => {
    return data;
  }));
}

getTransactions(page, itemsPerPage) {
  return this.http.get(this.baseUrl + environment.apiEndPoints.IGTransactions+"?page="+page+"&itemsPerPage="+itemsPerPage).pipe(map(data => {
    return data;
  }));
}

getIGDelayClaimByStatus(page, itemsPerPage, status) {
  console.log(page)
  console.log(itemsPerPage)
  console.log(status)
  return this.http.get(this.baseUrl + environment.apiEndPoints.getIGDelayClaimsByStatus+"?page="+page+"&itemsPerPage="+itemsPerPage+"&status="+status).pipe(map(data => {
    return data;
  }));
}

getSMS(page, itemsPerPage, type, customer, fromDate, toDate) {
  return this.http.get(this.baseUrl + environment.apiEndPoints.getSMSEmail+"?page="+page+"&itemsPerPage="+itemsPerPage+"&type="+type+"&customer="+customer+"&fromDate="+fromDate+"&toDate="+toDate).pipe(map(data => {
    return data;
  }));
}

getIntimateClaimData() {
  return this.http.get(this.baseUrl + environment.apiEndPoints.intimateClaimsData).pipe(map(data => {
    return data;
  }));
}

getClaimDataFromClient() {
  return this.http.get(this.baseUrl + environment.apiEndPoints.claimDetailsFromClient).pipe(map(data => {
    return data;
  }));
}
searchData(seachInput){
  return this.http.post(this.baseUrl + environment.apiEndPoints.getFileTrackerData, seachInput).pipe(map(data => {
    return data;
  }));
}
downloadFileForFileTracker(fileName) {
  const data ={
    "fileId":fileName
  }
  return this.http.post(this.baseUrl + environment.apiEndPoints.downloadFileForFileTracker ,data).pipe(
    map(data => {
      return data;
    })
  );
 }
 sendPaymentLink(data) {
  return this.http.post(this.baseUrl + environment.apiEndPoints.sendPaymentLink, data).pipe(map(data => {
    return data;
  }));
}
 getPolicyInstances(policyNumber) {
  return this.http.get(this.baseUrl + environment.apiEndPoints.getpolicyInstance+"?policyNumber="+policyNumber).pipe(map(data => {
    return data;
  }));
}
  
  getWhatsappTemplates() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getwhatapptemplates).pipe(map(data => {
      return data;
    }));
  }
  createWhatsappTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.createwhatapptemplates,data).pipe(map(data => {
      return data;
    }));
  }
  updateWhatsappTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updatewhatapptemplates,data).pipe(map(data => {
      return data;
    }));
  }
  deleteWhatsappTemplate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.deletewhatapptemplates,data).pipe(map(data => {
      return data;
    }));
  }


  // claim service

  saveClaim(claim: Claim) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveClaim, claim).pipe(map(data => {
      return data;
    }));
  }
  saveInboundClaim(id) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveInboundClaim, id).pipe(map(data => {
      return data;
    }));
  }
  updateInboundClaim(id) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateInboundClaim, id).pipe(map(data => {
      return data;
    }));
  }

  saveClaimEclaim(claimType, incidentDate, incidentCity, incidentCountry, initialReservAmountUSD, currency, customerfirstname, customerlastname, phone, email, claim) {    
    let test = 'test'
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveClaimFromEclaim+`?claimType=${claimType}&incidentDate=${incidentDate}&incidentCity=${incidentCity}&incidentCountry=${incidentCountry}&initialReservAmountUSD=${initialReservAmountUSD}&currency=${currency}&customerfirstname=${customerfirstname}&customerlastname=${customerlastname}&phone=${phone}&email=${email}`, claim).pipe(map(data => {
      return data;
    }));
  }
  saveClaimEclaimOne(file: File, eclaim) {    
    let formData: FormData = new FormData();
    formData.append('imgUploader', file);
    eclaim.file = formData;
    // console.log(eclaim, 'test data to send')
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveClaimFromEclaim+`?file=${formData}`, eclaim).pipe(map(data => {
      return data;
    }));
  }

  updateClaim(claim: UpdateClaimForm) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateClaim, claim).pipe(map(data => {
      return data;
    }));
  }
  updateClaimStatusWithLog(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateClaimStatusWithLog, data).pipe(map(data => {
      return data;
    }));
  }  

  updateCas(cas: Cas) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveCAS, cas).pipe(map(data => {
      return data;
    }));
  }

  updatePayable(payable: updatePayable) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.savePayable, payable).pipe(map(data => {
      return data;
    }));
  }

  updatePayableNew(payable) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.savePayableArray, payable).pipe(map(data => {
      return data;
    }));
  }

  currencyConvertor(incidentDate, base, to, incidentEndDate){
    // console.log('raju api test')
    // console.log(this.baseUrl + environment.apiEndPoints.rateConvertor+"?start_at="+incidentDate+"&end_at="+incidentEndDate+"&baseCurrency="+base+"&toCurrency="+to)
    return this.http.get(this.baseUrl + environment.apiEndPoints.rateConvertor+"?start_at="+incidentDate+"&end_at="+incidentEndDate+"&baseCurrency="+base+"&toCurrency="+to).pipe(map(data => {
      return data;
    }));
  }

  newcurrencyConvertor(date){
    // return this.http.get(`http://api.currencylayer.com/historical?access_key=a4267a8e7e3bb08bf743839ada7fe1d3&date=${date}`).pipe(map(data => {
    //   return data;
    // }));
    return this.http.get(this.baseUrl + environment.apiEndPoints.newrateConvertor+"?date="+date).pipe(map(data => {
      return data;
    }));
  }

  searchClaim(claim: UpdateClaim) {
    let queryParams: string = '';
    if (claim) {
      if (claim.caseNumber)
        queryParams += 'caseNumber=' + claim.caseNumber;
      if (claim.status)
        queryParams += '&caseStatus=' + claim.status;
      if (claim.policyNumber)
        queryParams += '&policyNumber=' + claim.policyNumber;
      if (claim.customerName)
        queryParams += '&customerName=' + claim.customerName;
      if (claim.claimDate)
        queryParams += '&Date=' + claim.claimDate;
      // console.log(queryParams, 'params raju test');
      return this.http.get(this.baseUrl + environment.apiEndPoints.claimSearch + '?' + queryParams).pipe(map(data => {
        return data;
      }));
    }
  }


    searchClaimByClientWithDates(body) {
      return this.http.post(this.baseUrl + environment.apiEndPoints.claimSearchByClient, body).pipe(map(data => {
        return data;
      }));
    }

  searchClaimByClient(insuranceProvider: string,
    filterByKey: string,
    filterByValue: string) {
      if (filterByKey != "All") {
        filterByValue = filterByValue.trim()
      }
      let body: any = {};
      if (insuranceProvider)
      body.clientName = insuranceProvider;
      if (filterByKey == "ClaimId")
        body.caseNumber = filterByValue;
      if (filterByKey == "CustomerName")
        body.customerName = filterByValue;
      if (filterByKey == "PolicyNumber")
        body.policyNumber = filterByValue;
      if (filterByKey == "certificateNumber")
        body.certificateNumber = filterByValue;
      if (filterByKey == "passportNumber")
        body.passportNumber = filterByValue;
      if (filterByKey == "Status")
        body.caseStatus = filterByValue;
      if (filterByKey == "Date")
        body.Date = filterByValue;
        if (filterByKey == "All")
      
      
      // console.log("Raju")
      // console.log(body, 'body to send')
      return this.http.post(this.baseUrl + environment.apiEndPoints.claimSearchByClient, body).pipe(map(data => {
        return data;
      }));
    }

    getClaimHistory(insuranceProvider: string,
      filterByKey: string,
      filterByValue: string) {
        if (filterByKey != "All") {
          filterByValue = filterByValue.trim()
        }
        let body: any = {};
        if (insuranceProvider) body.clientName = insuranceProvider;
        if (filterByKey == "ClaimId") body.caseNumber = filterByValue;
        if (filterByKey == "CustomerName") body.customerName = filterByValue;
        if (filterByKey == "PolicyNumber") body.policyNumber = filterByValue;
        if (filterByKey == "certificateNumber") body.certificateNumber = filterByValue;
        if (filterByKey == "passportNumber") body.passportNumber = filterByValue;
        if (filterByKey == "Status") body.caseStatus = filterByValue;
        if (filterByKey == "Date") body.Date = filterByValue;
        return this.http.post(this.baseUrl + environment.apiEndPoints.claimHistory, body).pipe(map(data => {
          return data;
        }));
      }
    getInboundClaimHistory(insuranceProvider: string,
      filterByKey: string,
      filterByValue: string) {
        if (filterByKey != "All") {
          filterByValue = filterByValue.trim()
        }
        let body: any = {};
        if (insuranceProvider) body.clientName = insuranceProvider;
        if (filterByKey == "ClaimId") body.caseNumber = filterByValue;
        if (filterByKey == "CustomerName") body.customerName = filterByValue;
        if (filterByKey == "PolicyNumber") body.policyNumber = filterByValue;
        if (filterByKey == "certificateNumber") body.certificateNumber = filterByValue;
        if (filterByKey == "passportNumber") body.passportNumber = filterByValue;
        if (filterByKey == "Status") body.caseStatus = filterByValue;
        if (filterByKey == "Date") body.Date = filterByValue;
        return this.http.post(this.baseUrl + environment.apiEndPoints.customerClaimHistory, body).pipe(map(data => {
          return data;
        }));
      }

    searchTravelAlerts(insuranceProvider: string, filterByKey: string, filterByValue: string, page, itemsPerPage, fromDate) {
        
        if (filterByKey != "All") {
          filterByValue = filterByValue.trim()
        }
        
        let body: any = {};
        if (insuranceProvider)
        body.insuranceProvider = insuranceProvider;
        body.fromDate = fromDate;
        if (insuranceProvider == 'All')
        body.insuranceProvider = '';
        body.page = page;
        body.itemsPerPage = itemsPerPage;
        if (filterByKey == "ClaimId")
          body.caseNumber = filterByValue;
        if (filterByKey == "CustomerName")
          body.customerName = filterByValue;
        if (filterByKey == "PolicyNumber")
          body.policyNumber = filterByValue;
        if (filterByKey == "certificateNumber")
          body.certificateNumber = filterByValue;
          
        if (filterByKey == "Status")
          body.caseStatus = filterByValue;
        if (filterByKey == "Date")
          body.Date = filterByValue;
          if (filterByKey == "All") {
            body.Date = '';
            body.caseStatus = '';
            body.certificateNumber = '';
            body.policyNumber = '';
            body.customerName = '';
            body.caseNumber = '';
            body.fromDate = '';
          }
        
        // console.log(body, 'test body');
        return this.http.post(this.baseUrl + environment.apiEndPoints.travelAlertsSearch, body).pipe(map(data => {
          return data;
        }));
      }
    searchInboundTravelAlerts(insuranceProvider: string, filterByKey: string, filterByValue: string, page, itemsPerPage,type, fromDate) {
        
        if (filterByKey != "All") {
          filterByValue = filterByValue.trim()
        }
        
        let body: any = {};
        if (insuranceProvider)
        body.insuranceProvider = insuranceProvider;
        body.fromDate = fromDate;
        if (insuranceProvider == 'All')
        body.insuranceProvider = '';
        body.page = page;
        body.type = type;
        body.itemsPerPage = itemsPerPage;
        if (filterByKey == "ClaimId")
          body.caseNumber = filterByValue;
        if (filterByKey == "CustomerName")
          body.customerName = filterByValue;
        if (filterByKey == "PolicyNumber")
          body.policyNumber = filterByValue;
        if (filterByKey == "certificateNumber")
          body.certificateNumber = filterByValue;
          
        if (filterByKey == "Status")
          body.caseStatus = filterByValue;
        if (filterByKey == "Date")
          body.Date = filterByValue;
          if (filterByKey == "All") {
            body.Date = '';
            body.caseStatus = '';
            body.certificateNumber = '';
            body.policyNumber = '';
            body.customerName = '';
            body.caseNumber = '';
            body.fromDate = '';
          }
        
        // console.log(body, 'test body');
        return this.http.post(this.baseUrl + environment.apiEndPoints.getInboundClaimAlerts, body).pipe(map(data => {
          return data;
        }));
      }

  getTodayDate() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.todaydate).pipe(map(data => {
      return data;
    }));
  }

  
  getInboundClaimLogs(caseId:string){
    return this.http.get(this.baseUrl+environment.apiEndPoints.inboundClaimLogs+'?key='+caseId).pipe(map(data=>{
      return data;
    }))
  }
  getClaimAlerts(page, itemsPerPage) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getClaimAlerts+"?page="+page+"&itemsPerPage="+itemsPerPage).pipe(map(data => {
      return data;
    }));
  }
  getInboundClaimAlerts(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getInboundClaimAlerts,data).pipe(map(data => {
      return data;
    }));
  }

  getCronData(page, itemsPerPage) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getCronData+"?page="+page+"&itemsPerPage="+itemsPerPage).pipe(map(data => {
      return data;
    }));
  }

  getCronDataById(id) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getCronDataById+"?_id="+id).pipe(map(data => {
      return data;
    }));
  }

  updatelogIdByClaim(travelCaseId) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.updatelogIdByClaim+"?travelCaseId="+travelCaseId).pipe(map(data => {
      return data;
    }));
  }

  
  getInvalidClaimAlerts(insuranceProvider: string, filterByKey: string, filterByValue: string,  fromDate) {
    if (filterByKey != "All") {
      filterByValue = filterByValue.trim()
    }
    
    let body: any = {};
    if (insuranceProvider)
    body.insuranceProvider = insuranceProvider;
    body.fromDate = fromDate;
    if (insuranceProvider == 'All')
    body.insuranceProvider = '';
    // body.page = page;
    // body.itemsPerPage = itemsPerPage;
    if (filterByKey == "ClaimId")
      body.caseNumber = filterByValue;
    if (filterByKey == "firstName")
      body.firstName = filterByValue;
    if (filterByKey == "lastName")
      body.lastName = filterByValue;
    if (filterByKey == "PolicyNumber")
      body.policyNumber = filterByValue;
    if (filterByKey == "certificateNumber")
      body.certificateNumber = filterByValue;
      
    // if (filterByKey == "Status")
    //   body.caseStatus = filterByValue;
    if (filterByKey == "Date")
      body.Date = filterByValue;
      if (filterByKey == "All") {
        body.Date = '';
        // body.caseStatus = '';
        body.certificateNumber = '';
        body.policyNumber = '';
        body.firstName = '';
        body.lastName = '';
        body.caseNumber = '';
        body.fromDate = '';
      }
    return this.http.post(this.baseUrl + environment.apiEndPoints.getInvalidClaimAlerts,body).pipe(map(data => {
      return data;
    }));
  }
  getRejectedClaimAlerts(insuranceProvider: string, filterByKey: string, filterByValue: string, fromDate) {
    if (filterByKey != "All") {
      filterByValue = filterByValue.trim()
    }
    
    let body: any = {};
    if (insuranceProvider)
    body.insuranceProvider = insuranceProvider;
    body.fromDate = fromDate;
    if (insuranceProvider == 'All')
    body.insuranceProvider = '';
    // body.page = page;
    // body.itemsPerPage = itemsPerPage;
    if (filterByKey == "ClaimId")
      body.caseNumber = filterByValue;
    if (filterByKey == "firstName")
      body.firstName = filterByValue;
    if (filterByKey == "lastName")
      body.lastName= filterByValue;
    if (filterByKey == "PolicyNumber")
      body.policyNumber = filterByValue;
    if (filterByKey == "certificateNumber")
      body.certificateNumber = filterByValue;
    if (filterByKey == "Date")
      body.Date = filterByValue;
      if (filterByKey == "All") {
        body.Date = '';
        // body.caseStatus = '';
        body.certificateNumber = '';
        body.policyNumber = '';
        body.firstName = '';
        body.lastName = '';
        body.caseNumber = '';
        body.fromDate = '';
      }
    return this.http.post(this.baseUrl + environment.apiEndPoints.getRejectedClaimAlerts,body).pipe(map(data => {
      return data;
    }));
  }

  getFlightDelays() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getFlightDelays).pipe(map(data => {
      return data;
    }));
  }

  getFlightCancels() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getFlightCancels).pipe(map(data => {
      return data;
    }));
  }
  
  getcheckdocument(data){
    return this.http.post(this.baseUrl+environment.apiEndPoints.checkdocument,data).pipe(map(data=>{
      return data;
    }))
  }

  getCheckLog(data){
    return this.http.post(this.baseUrl+environment.apiEndPoints.checkLogRecord,data).pipe(map(data=>{
      return data;
    }))
  }

  getCheckLogNew(data){
    return this.http.post(this.baseUrl+environment.apiEndPoints.checkLogRecordNew, data).pipe(map(data=>{
      return data;
    }))
  }

  updateClaimStatus(status: string, caseId: string,caseNumber:string) {
    let ClaimStatus = {
      "caseId": caseId,
      "status": status,
      "caseNumber":caseNumber
    }
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateClaimStatus, ClaimStatus).pipe(map(data => {
      return data;
    }));
  }

  updateCurrentClaimStatus(status: string, caseId: string ) {
    let ClaimStatus = {
      "travelCaseId": caseId,
      "status": status,
     
    }
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateCurrentClaimStatus, ClaimStatus).pipe(map(data => {
      return data;
    }));
  }

  getNextStatus(currentStatus: string, workFlowObjkey:string) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getNextFlowStatus + '?workFlowObjectKey=' + workFlowObjkey+'&status='+currentStatus).pipe(map(data => {
      return data;
    }));
  }

  uploadDocuments(file: File, casefileType: string, travelCaseId: string, docComment:string ) {
    if (file != undefined) {
      let formData: FormData = new FormData();
      //formData.append('Content-Disposition', file);
      formData.append('imgUploader', file);
      //formData.append('Content-Type', file.type);
      // console.log(casefileType, 'doc type id')
      return this.http.post(this.baseUrl + environment.apiEndPoints.uploadDocument + `?docTypeId=${casefileType}&travelCaseId=${travelCaseId}&docComment=${docComment}`, formData)
        .pipe(map(doc => {
          return doc;
        }));

    }
    else {
      window.alert("Please add a document to upload")
    }
  }

  uploadOPDDocuments(file: File, casefileType: string, travelCaseId: string, docComment:string, isPOD:boolean ) {
    if (file != undefined) {
      let formData: FormData = new FormData();
      //formData.append('Content-Disposition', file);
      formData.append('imgUploader', file);
      //formData.append('Content-Type', file.type);
      // console.log(casefileType, 'doc type id')
      return this.http.post(this.baseUrl + environment.apiEndPoints.uploadDocument + `?docTypeId=${casefileType}&travelCaseId=${travelCaseId}&docComment=${docComment}&isPOD=${isPOD}`, formData)
        .pipe(map(doc => {
          return doc;
        }));

    }
    else {
      window.alert("Please add a document to upload")
    }
  }

  uploadMultipleDocs(file: File) {
    if (file != undefined) {
      let formData: FormData = new FormData();
      formData.append('imgUploader', file);
      // console.log(formData, 'form data')
      let uploadarr = [
        {
          "docTypeId" : "INVOICE",
          "travelCaseId" : "608fe05d09839a31e87b67c7",
          "file" : "INVOICE"
        },
        {
          "docTypeId" : "PAN CARD",
          "travelCaseId" : "608fe05d09839a31e87b67c7",
          "file" : "INVOICE"
        }
      ]
      // console.log(uploadarr, 'upload array')
      return this.http.post(this.baseUrl + environment.apiEndPoints.uploadMultipleDocs, uploadarr)
        .pipe(map(doc => {
          return doc;
        }));
    }
    else {
      window.alert("Please add a document to upload")
    }
  }

  
  updateDocumentStatus(travelCaseId: string, docuemntId: string, status: string,caseNumber:string,docTypeId:string, documentName: string) {

    let updateDocumentStatus = {    "travelCaseId" : travelCaseId,
                                    "documentId" : docuemntId,
                                    "status":status,
                                    "caseNumber":caseNumber,
                                    "docTypeId":docTypeId,
                                    'documentName': documentName
                                  }

      return this.http.post(this.baseUrl + environment.apiEndPoints.UpdateDocumentStatus, updateDocumentStatus).pipe(map(response => {
        return response;
      }));
  }

  travelCaseUpdateDoc(documentName: string, casefileType: string, travelCaseId: string) {
    let travelCaseDocument = {
      "documentName": documentName,
      "docTypeId": casefileType,
      "travelCaseId": travelCaseId
    }
    return this.http.post(this.baseUrl + environment.apiEndPoints.travelCaseUpdateDoc, travelCaseDocument)
      .pipe(map(response => {
        return response;
      }));
  }
  travelCaseDeleteDoc(documentName: string,  travelCaseId: string, comment: string) {
    let travelCaseDocument = {
      "documentName": documentName,
      "travelCaseId": travelCaseId,
      "comment": comment
    }
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteDocument, travelCaseDocument)
      .pipe(map(response => {
        return response;
      }));
  }

  saveLog(logComment: string, logType: string, channel: string, direction: string, source:string, travelCaseId: string,loggedInUser:string) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveLogs, { comment: logComment, logType: logType,channel:channel,direction:direction,source:source, travelCaseId: travelCaseId,createdBy:loggedInUser}).pipe(map(data => {
      return data;
    }));
  }

  saveLogNew(logComment: string, logType: string, channel: string, direction: string, source:string, travelCaseId: string,loggedInUser:string, caseNumber:string, clientId:string, claimType:string, subClaimType:string, taskAssignee:string, logs_id:any) {
    // console.log(caseNumber, 'caseNumber')
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveLogsNewCollection, { comment: logComment, logType: logType,channel:channel,direction:direction,source:source, travelCaseId: travelCaseId, createdBy: loggedInUser, caseNumber: caseNumber, clientId:clientId, claimType:claimType, subClaimType:subClaimType, taskAssignee: taskAssignee, logs_id: logs_id}).pipe(map(data => {
      return data;
    }));
  }

  saveNote(logComment: string, travelCaseId: string, loggedInUser:string, caseNumber:string) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveNote, { comment: logComment, travelCaseId: travelCaseId,createdBy:loggedInUser, caseNumber: caseNumber}).pipe(map(data => {
      return data;
    }));
  }

  getNotes(page, itemsPerPage, travelCaseId) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getNotes+"?page="+page+"&itemsPerPage="+itemsPerPage+"&travelCaseId="+travelCaseId).pipe(map(data => {
      return data;
    }));
  }

  getMailIds(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getToEmails, data).pipe(map(data => {
      return data;
    }));
  }
 

  getClaimDetails(claimId){
    // console.log(claimId)
    let myp=new HttpParams();
    let ad=myp.set("claimId",claimId)
    // console.log('Hare ',ad);
    return this.http.get(this.baseUrl + environment.apiEndPoints.getClaimDetails,{params:ad}).pipe(map(data => {
      return data;
      
    }));
  }
  saveClaimDetails(data){
    // console.log(data)
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveClaimDetails, data).pipe(map(data => {
      return data;
      
    }));
  }

  uploadDocumentsService(file: File, casefileType: string, travelCaseId: string ) {
    if (file != undefined) {
      let formData: FormData = new FormData();
      formData.append('imgUploader', file);
      return this.http.post(this.baseUrl + environment.apiEndPoints.uploadDocument + `?docTypeId=${casefileType}&travelCaseId=${travelCaseId}`, formData)
        .pipe(map(doc => {
          return doc;
        }));
    }
    else {
      window.alert("Please add a document to upload")
    }
  }

 deleteClaimDetails(data){
  return this.http.post(this.baseUrl + environment.apiEndPoints.deleteClaimDetails,data).pipe(map(data => {
    return data;
  }));

 }
 updateClaimDetails(data){
  {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateClaimDetails,data).pipe(map(data => {
      return data;
    }));
  
   }
  }

  payableUpdate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updatePayable,data).pipe(map(data => {
      return data;
    }));
  }

  saveNewNote(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveNewNote, data).pipe(map(data => {
      return data;
    }));
  }

  getNewNote(data) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getNewNote+"?_id="+data).pipe(map(data => {
      return data;
    }));
  }

  getFileTrackerById(caseId: string) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.fileTrackerById + '?claimFileNumber=' + caseId).pipe(map(data => {
      return data;
    }));
  }

  updateFileTrackerData(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateFileTrackerData, data).pipe(map(data => {
      return data;
    }));
  }

  saveFileTrackerData(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveFileTrackerData, data).pipe(map(data => {
      return data;
    }));
  }

  getAllRGICDocs(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getAllRGICDocuments, data).pipe(map(data => {
      return data;
    }));
  }

  getDocByteStreamRGIC(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getDocumentByteStreamRGIC, data).pipe(map(data => {
      return data;
    }));
  }

  getRGICPolicyCopy(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getRGICPolicyCopy, data).pipe(map(data => {
      return data;
    }));
  }
  getCommunicationLetterFromRGIC(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getRGICommunicationLetter, data).pipe(map(data => {
      return data;
    }));
  }

  getDocterDetails() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getDoctors).pipe(map(data => {
      return data;
    }));
  }

  getParticularDetail(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.searchDocter, data).pipe(map(data => {
      return data;
    }));
  }

  updateDoctor(client) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateDoctor, client).pipe(map(data => {
      return data;
    }));
  }
  agentsClaimCount(){
    return this.http.get(this.baseUrl + environment.apiEndPoints.agentCase ).pipe(map(data => {
      return data;
    }));
  }
  
  getUnassignedCases(insuranceProvider: string, filterByKey: string, filterByValue: string, page, itemsPerPage, fromDate){
    if (filterByKey != "All") {
      filterByValue = filterByValue.trim()
    }
    
    let body: any = {};
    if (insuranceProvider)
    body.insuranceProvider = insuranceProvider;
    body.fromDate = fromDate;
    body.page = page;
    body.itemsPerPage = itemsPerPage;
    if (insuranceProvider == 'All')
    body.insuranceProvider = '';
    body.page = page;
    body.itemsPerPage = itemsPerPage;
    if (filterByKey == "ClaimId")
      body.caseNumber = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
    if (filterByKey == "CustomerName")
      body.customerName = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
    if (filterByKey == "PolicyNumber")
      body.policyNumber = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
    if (filterByKey == "certificateNumber")
      body.certificateNumber = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
      
    if (filterByKey == "Status")
      body.caseStatus = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
    if (filterByKey == "Date")
      body.Date = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
      if (filterByKey == "All") {
        body.Date = '';
        body.caseStatus = '';
        body.certificateNumber = '';
        body.policyNumber = '';
        body.customerName = '';
        body.caseNumber = '';
        body.fromDate = '';
        body.page = page;
        body.itemsPerPage = itemsPerPage;
      }
    
    return this.http.post(this.baseUrl + environment.apiEndPoints.getAllCases, body).pipe(map(data => {
      return data;
    }));
  }
  
  

  getCasesReassigned(insuranceProvider: string, filterByKey: string, filterByValue: string, page, itemsPerPage, fromDate){
    if (filterByKey != "All") {
      filterByValue = filterByValue.trim()
    }
    
    let body: any = {};
    if (insuranceProvider)
    body.insuranceProvider = insuranceProvider;
    body.fromDate = fromDate;
    body.page = page;
    body.itemsPerPage = itemsPerPage;
    if (insuranceProvider == 'All')
    body.insuranceProvider = '';
    body.page = page;
    body.itemsPerPage = itemsPerPage;
    if (filterByKey == "ClaimId")
      body.caseNumber = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
    if (filterByKey == "CustomerName")
      body.customerName = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
    if (filterByKey == "PolicyNumber")
      body.policyNumber = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
    if (filterByKey == "certificateNumber")
      body.certificateNumber = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
      
    if (filterByKey == "Status")
      body.caseStatus = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
    if (filterByKey == "Date")
      body.Date = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
      if (filterByKey == "All") {
        body.Date = '';
        body.caseStatus = '';
        body.certificateNumber = '';
        body.policyNumber = '';
        body.customerName = '';
        body.caseNumber = '';
        body.fromDate = '';
        body.page = page;
        body.itemsPerPage = itemsPerPage;
      }
    
    return this.http.post(this.baseUrl + environment.apiEndPoints.reAssingmentCases, body).pipe(map(data => {
      return data;
    }));
  }

  getAllOpenCases(){
    return this.http.get(this.baseUrl + environment.apiEndPoints.getAllOpenCases).pipe(map(data => {
      return data;
    }));
  }
  getNewOpenCases(body){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getOpenCasesByPagination,body).pipe(map(data => {
      return data;
    }));
  }

  getAgentAssingedCases(body){
    return this.http.post(this.baseUrl + environment.apiEndPoints.agentAssingedCases,body).pipe(map(data => {
      return data;
    }));
  }

  getAgentAssingedCasePerPage(body){
    return this.http.post(this.baseUrl + environment.apiEndPoints.agentAssingedCasesPerPages,body).pipe(map(data => {
      return data;
    }));
  }

}
