import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {  Router} from '@angular/router';
import { map } from 'rxjs/operators';
import * as jwt_decode from "jwt-decode";


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl = environment.authURL;
  private apiUrl = environment.apiURL;
  private logoUrl = environment.ssoURL;
  private ratingUrl = environment.ezURL;
  constructor(private http: HttpClient,private router:Router) { }

  public getToken(): string {    
    let token = sessionStorage.getItem('token');
    return token;
  }

  public getOauthToken(): string {    
    let oAuthtoken = sessionStorage.getItem('oAuthtoken');
    return oAuthtoken;
  }

  public isAuthenticated(): boolean {
    // get the token
    const token = this.getToken();
    // return a boolean reflecting 
    // whether or not the token is expired
    //return tokenNotExpired(token);
    let tokeData = jwt_decode(token);
    let  url = this.router.url;
    let user = url.split('/')[1];
  
    // if(tokeData.role == "admin"){
    //     if(user == "admin"){
    //         return true;
    //     }else{
    //       return false;
    //     }
    // }else if(tokeData.role == "agent" || tokeData.role == "supervisor" || tokeData.role == "doctor"){
    //   if(user == "agent"){
    //     return true;
    //     }else{
    //       return false;
    //     }
    // }
    return !!token;
  }

  checkOAuthValidity() {
    console.log("testing fight")
    let validateCheck = sessionStorage.getItem('validateCheck');
    console.log(validateCheck, 'validity')
    return this.http.post(environment.ssoURL + environment.apiEndPoints.oAuthValidation, validateCheck).pipe(map(data=>{
      console.log(data, 'data')
      return data;
    }))
  }

  
  signIn(UserName: string, Password: string) {
    var body = {
      'user': {
        'user_name': UserName,
        'password': Password
      }
    };
    return this.http.post(this.baseUrl + environment.apiEndPoints.login, body).pipe(map(data => {
      return data;
    }));
  }
  submitScore(score) {
    return this.http.post(this.ratingUrl +"batchJobs/" + environment.apiEndPoints.saveScore, score).pipe(map(data => {
      return data;
    }));
  }
  submitNpsScore(score) {
    return this.http.post(this.ratingUrl +"batchJobs/" + environment.apiEndPoints.saveNpsScore, score).pipe(map(data => {
      return data;
    }));
  }

  getCustomerJwt(){
    return this.http.get(this.baseUrl + environment.apiEndPoints.generateJwt).pipe(map(data => {
      return data;
    }));
  }
  getUserDetailsByEmail(email) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getDetailsByemail+'?email='+email).pipe(map(data => {
      return data;
    }));
  }
  getclientLogo(provider) {
    return this.http.get(this.logoUrl + environment.apiEndPoints.getClientLogo+'?roleName=ClientRM&applicationName=Eclaims&clientName='+ provider).pipe(map(data => {
      return data;
    }));
  }
  getclientName(provider) {
    return this.http.get(this.ratingUrl +"batchJobs/" + environment.apiEndPoints.getClientName + '?clientId='+provider).pipe(map(data => {
      return data;
    }));
  }
  getcsatSubmitStatus(provider) {
    return this.http.get(this.ratingUrl +"batchJobs/" + environment.apiEndPoints.getCsatStatus + '?serviceNo='+ provider).pipe(map(data => {
      return data;
    }));
  }

  sendMessage(form) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.sendMail, form).pipe(map(data => {
      return data;
    }));
    
  }

  sendMessageandMail(phoneNumber, email) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.sendSmsAndMail+'?phoneNumber='+phoneNumber+'?email='+email ).pipe(map(data => {
      return data;
    }));
  }

  resetPassword(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.changepassword,data).pipe(map(data=>{
      return data;
    }))
  }

  checkSSOSession(data){
    // let headers_object = new HttpHeaders({
    //   'Content-Type': 'application/json',
    //    'Authorization': "Bearer "+token
    // });

    //     const httpOptions = {
    //       headers: headers_object
    //     };
    return this.http.post(environment.ssoURL + environment.apiEndPoints.oAuthValidation, data).pipe(map(data=>{
      return data;
    }))
  }

  getServerKey(data) {
    return this.http.post(environment.ssoURL + environment.apiEndPoints.getServerKey, data).pipe(map(data=>{
      return data;
    }))
  }

  getTokenForSSo(data) {
    return this.http.post(environment.ezURL + environment.apiEndPoints.getEzToken, data).pipe(map(data=>{
      return data;
    }))
  }

  getCommonPortalPermissions(data) {
    return this.http.post(`https://cpuatapi.europ-assistance.in/api/sso/roleAndPagesOfUser`, data).pipe(map(data=>{
      return data;
    }))
  }

  getSSOUserDetails(data) {
    return this.http.post(environment.ezURL + environment.apiEndPoints.getUserSSODetails, data).pipe(map(data=>{
      return data;
    }))
  }



}
