import { Component, OnInit ,ComponentRef} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PolicyService } from 'src/app/services/policy.service';
import * as CryptoJS from 'crypto-js';
import { MasterService } from 'src/app/services/master.service';
import { FormBuilder, FormGroup, Validators,FormArray } from '@angular/forms';
import { NumericPattern} from 'src/app/_helpers/custom.validator';
import { CreateService } from 'src/app/services/create.service';
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-docter-details-new-add',
  templateUrl: './docter-details-new-add.component.html',
  styleUrls: ['./docter-details-new-add.component.scss']
})
export class DocterDetailsAddition implements OnInit {
  title: string;
  submitted:boolean=false
  closeBtnName: string;
  customerId : any;
  documentForm: FormGroup;
  modalRef: BsModalRef;
// Initialize customerData and claimsList
customerData: any; // You can specify a more specific type if available
claimsList: any[] = []; // An array to store multiple claims

  constructor(public bsModalRef: BsModalRef, public masterService:MasterService,
    private formBuilder: FormBuilder, private createService:CreateService,private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.documentForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName:['',Validators.required],
      contactNumber:['',[Validators.required, Validators.pattern(NumericPattern())]],
      emailId: ['', [Validators.required,Validators.email]],
      specializations:['',Validators.required],
      status: ['', Validators.required],
      illnesses: this.formBuilder.array([this.createIllnessRow()])
    })  
  }

  get f() { return this.documentForm.controls; }
  get illnesses(): FormArray {
    return this.documentForm.get('illnesses') as FormArray;
  }
  createIllnessRow(illnessData: any = {}): FormGroup {
    return this.formBuilder.group({
      illness: [illnessData.illness || '', Validators.required],
      consultationFees: [illnessData.consultationFees || '', Validators.required]
    });
  }
  addIllnessRow(): void {
    this.illnesses.push(this.createIllnessRow());
  }
  numericEntry(event): boolean {
    let patt = /^([0-9\^])$/;
    let result = patt.test(event.key);
    return result;
  }
  addIllnessRowWithValues(): void {
    const initialData = {
      illness: 'Some Illness',
      consultationFees: 100
    };
    this.illnesses.push(this.createIllnessRow(initialData));
  }
  removeIllnessRow(index: number): void {
    if (this.illnesses.length > 1) {
      this.illnesses.removeAt(index);
    }
  }

  saveDocument() {
   this.submitted = true
    if (this.documentForm.invalid) {
            return;
    }
    console.log("FormValues",this.documentForm.value)
    let saveDoctorDetails = this.documentForm.value
      this.createService.createDoctor(saveDoctorDetails).subscribe(res => {
        this.documentForm.get('firstName').setValue('')
        this.documentForm.get('lastName').setValue('')
        this.documentForm.get('contactNumber').setValue('')
        this.documentForm.get('emailId').setValue('')
        this.documentForm.get('specializations').setValue('')
        this.documentForm.get('status').setValue('');
         // this.getDoctors();        
          this.toastr.success("Doctor Details Added Successfully");
          this.submitted = false;
          this.bsModalRef.hide();
         // BsModalRef.hide();
  
      })
  }

  encryptCustomerId(customerId: string): string {
    const secretKey = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // Replace with your secret key
    const encrypted = CryptoJS.AES.encrypt(customerId, secretKey);
    return encrypted.toString();
  }
  encryptCaseId(caseId: string): string {
    const secretKey = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // Replace with your secret key
    const encrypted = CryptoJS.AES.encrypt(caseId, secretKey);
    return encrypted.toString();
  }

}
