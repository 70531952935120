import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { UpdateClaim, Claim, Cas, UpdateClaimForm, updatePayable } from '../_shared/models/claim.model'
@Injectable({
  providedIn: 'root'
})

export class ClaimsService {

  private baseUrl = environment.apiURL;
  constructor(private http: HttpClient) { }

  saveClaim(claim: Claim) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveClaim, claim).pipe(map(data => {
      return data;
    }));
  }
  saveInboundClaim(id) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveInboundClaim, id).pipe(map(data => {
      return data;
    }));
  }
  updateInboundClaim(id) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateInboundClaim, id).pipe(map(data => {
      return data;
    }));
  }

  saveClaimEclaim(claimType, incidentDate, incidentCity, incidentCountry, initialReservAmountUSD, currency, customerfirstname, customerlastname, phone, email, claim) {    
    let test = 'test'
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveClaimFromEclaim+`?claimType=${claimType}&incidentDate=${incidentDate}&incidentCity=${incidentCity}&incidentCountry=${incidentCountry}&initialReservAmountUSD=${initialReservAmountUSD}&currency=${currency}&customerfirstname=${customerfirstname}&customerlastname=${customerlastname}&phone=${phone}&email=${email}`, claim).pipe(map(data => {
      return data;
    }));
  }
  saveClaimEclaimOne(file: File, eclaim) {    
    let formData: FormData = new FormData();
    formData.append('imgUploader', file);
    eclaim.file = formData;
    // console.log(eclaim, 'test data to send')
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveClaimFromEclaim+`?file=${formData}`, eclaim).pipe(map(data => {
      return data;
    }));
  }

  updateClaim(claim: UpdateClaimForm) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateClaim, claim).pipe(map(data => {
      return data;
    }));
  }
  updateClaimStatusWithLog(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateClaimStatusWithLog, data).pipe(map(data => {
      return data;
    }));
  }  

  updateCas(cas: Cas) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveCAS, cas).pipe(map(data => {
      return data;
    }));
  }

  updatePayable(payable: updatePayable) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.savePayable, payable).pipe(map(data => {
      return data;
    }));
  }

  updatePayableNew(payable) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.savePayableArray, payable).pipe(map(data => {
      return data;
    }));
  }

  currencyConvertor(incidentDate, base, to, incidentEndDate){
    // console.log('raju api test')
    // console.log(this.baseUrl + environment.apiEndPoints.rateConvertor+"?start_at="+incidentDate+"&end_at="+incidentEndDate+"&baseCurrency="+base+"&toCurrency="+to)
    return this.http.get(this.baseUrl + environment.apiEndPoints.rateConvertor+"?start_at="+incidentDate+"&end_at="+incidentEndDate+"&baseCurrency="+base+"&toCurrency="+to).pipe(map(data => {
      return data;
    }));
  }

  newcurrencyConvertor(date){
    // return this.http.get(`http://api.currencylayer.com/historical?access_key=a4267a8e7e3bb08bf743839ada7fe1d3&date=${date}`).pipe(map(data => {
    //   return data;
    // }));
    return this.http.get(this.baseUrl + environment.apiEndPoints.newrateConvertor+"?date="+date).pipe(map(data => {
      return data;
    }));
  }

  searchClaim(claim: UpdateClaim) {
    let queryParams: string = '';
    if (claim) {
      if (claim.caseNumber)
        queryParams += 'caseNumber=' + claim.caseNumber;
      if (claim.status)
        queryParams += '&caseStatus=' + claim.status;
      if (claim.policyNumber)
        queryParams += '&policyNumber=' + claim.policyNumber;
      if (claim.customerName)
        queryParams += '&customerName=' + claim.customerName;
      if (claim.claimDate)
        queryParams += '&Date=' + claim.claimDate;
      // console.log(queryParams, 'params raju test');
      return this.http.get(this.baseUrl + environment.apiEndPoints.claimSearch + '?' + queryParams).pipe(map(data => {
        return data;
      }));
    }
  }


    searchClaimByClientWithDates(body) {
      return this.http.post(this.baseUrl + environment.apiEndPoints.claimSearchByClient, body).pipe(map(data => {
        return data;
      }));
    }

  searchClaimByClient(insuranceProvider: string,
    filterByKey: string,
    filterByValue: string) {
      if (filterByKey != "All") {
        filterByValue = filterByValue.trim()
      }
      let body: any = {};
      if (insuranceProvider)
      body.clientName = insuranceProvider;
      if (filterByKey == "ClaimId")
        body.caseNumber = filterByValue;
      if (filterByKey == "CustomerName")
        body.customerName = filterByValue;
      if (filterByKey == "PolicyNumber")
        body.policyNumber = filterByValue;
      if (filterByKey == "certificateNumber")
        body.certificateNumber = filterByValue;
      if (filterByKey == "passportNumber")
        body.passportNumber = filterByValue;
      if (filterByKey == "Status")
        body.caseStatus = filterByValue;
      if (filterByKey == "Date")
        body.Date = filterByValue;
        if (filterByKey == "All")
      
      
      // console.log("Raju")
      // console.log(body, 'body to send')
      return this.http.post(this.baseUrl + environment.apiEndPoints.claimSearchByClient, body).pipe(map(data => {
        return data;
      }));
    }

    getClaimHistory(insuranceProvider: string,
      filterByKey: string,
      filterByValue: string) {
        if (filterByKey != "All") {
          filterByValue = filterByValue.trim()
        }
        let body: any = {};
        if (insuranceProvider) body.clientName = insuranceProvider;
        if (filterByKey == "ClaimId") body.caseNumber = filterByValue;
        if (filterByKey == "CustomerName") body.customerName = filterByValue;
        if (filterByKey == "PolicyNumber") body.policyNumber = filterByValue;
        if (filterByKey == "certificateNumber") body.certificateNumber = filterByValue;
        if (filterByKey == "passportNumber") body.passportNumber = filterByValue;
        if (filterByKey == "Status") body.caseStatus = filterByValue;
        if (filterByKey == "Date") body.Date = filterByValue;
        return this.http.post(this.baseUrl + environment.apiEndPoints.claimHistory, body).pipe(map(data => {
          return data;
        }));
      }
    getInboundClaimHistory(insuranceProvider: string,
      filterByKey: string,
      filterByValue: string) {
        if (filterByKey != "All") {
          filterByValue = filterByValue.trim()
        }
        let body: any = {};
        if (insuranceProvider) body.clientName = insuranceProvider;
        if (filterByKey == "ClaimId") body.caseNumber = filterByValue;
        if (filterByKey == "CustomerName") body.customerName = filterByValue;
        if (filterByKey == "PolicyNumber") body.policyNumber = filterByValue;
        if (filterByKey == "certificateNumber") body.certificateNumber = filterByValue;
        if (filterByKey == "passportNumber") body.passportNumber = filterByValue;
        if (filterByKey == "Status") body.caseStatus = filterByValue;
        if (filterByKey == "Date") body.Date = filterByValue;
        return this.http.post(this.baseUrl + environment.apiEndPoints.customerClaimHistory, body).pipe(map(data => {
          return data;
        }));
      }

    searchTravelAlerts(insuranceProvider: string, filterByKey: string, filterByValue: string, page, itemsPerPage, fromDate) {
        
        if (filterByKey != "All") {
          filterByValue = filterByValue.trim()
        }
        
        let body: any = {};
        if (insuranceProvider)
        body.insuranceProvider = insuranceProvider;
        body.fromDate = fromDate;
        if (insuranceProvider == 'All')
        body.insuranceProvider = '';
        body.page = page;
        body.itemsPerPage = itemsPerPage;
        if (filterByKey == "ClaimId")
          body.caseNumber = filterByValue;
        if (filterByKey == "CustomerName")
          body.customerName = filterByValue;
        if (filterByKey == "PolicyNumber")
          body.policyNumber = filterByValue;
        if (filterByKey == "certificateNumber")
          body.certificateNumber = filterByValue;
          
        if (filterByKey == "Status")
          body.caseStatus = filterByValue;
        if (filterByKey == "Date")
          body.Date = filterByValue;
          if (filterByKey == "All") {
            body.Date = '';
            body.caseStatus = '';
            body.certificateNumber = '';
            body.policyNumber = '';
            body.customerName = '';
            body.caseNumber = '';
            body.fromDate = '';
          }
        
        // console.log(body, 'test body');
        return this.http.post(this.baseUrl + environment.apiEndPoints.travelAlertsSearch, body).pipe(map(data => {
          return data;
        }));
      }
    searchInboundTravelAlerts(insuranceProvider: string, filterByKey: string, filterByValue: string, page, itemsPerPage,type, fromDate) {
        
        if (filterByKey != "All") {
          filterByValue = filterByValue.trim()
        }
        
        let body: any = {};
        if (insuranceProvider)
        body.insuranceProvider = insuranceProvider;
        body.fromDate = fromDate;
        if (insuranceProvider == 'All')
        body.insuranceProvider = '';
        body.page = page;
        body.type = type;
        body.itemsPerPage = itemsPerPage;
        if (filterByKey == "ClaimId")
          body.caseNumber = filterByValue;
        if (filterByKey == "CustomerName")
          body.customerName = filterByValue;
        if (filterByKey == "PolicyNumber")
          body.policyNumber = filterByValue;
        if (filterByKey == "certificateNumber")
          body.certificateNumber = filterByValue;
          
        if (filterByKey == "Status")
          body.caseStatus = filterByValue;
        if (filterByKey == "Date")
          body.Date = filterByValue;
          if (filterByKey == "All") {
            body.Date = '';
            body.caseStatus = '';
            body.certificateNumber = '';
            body.policyNumber = '';
            body.customerName = '';
            body.caseNumber = '';
            body.fromDate = '';
          }
        
        // console.log(body, 'test body');
        return this.http.post(this.baseUrl + environment.apiEndPoints.getInboundClaimAlerts, body).pipe(map(data => {
          return data;
        }));
      }

    
  

  getClaim(caseId: string) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.claimDetails + '?caseNumber=' + caseId).pipe(map(data => {
      return data;
    }));
  }

  getTodayDate() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.todaydate).pipe(map(data => {
      return data;
    }));
  }

  getClaimLogs(caseId:string){
    return this.http.get(this.baseUrl+environment.apiEndPoints.claimLogs+'?key='+caseId).pipe(map(data=>{
      return data;
    }))
  }
  getInboundClaimLogs(caseId:string){
    return this.http.get(this.baseUrl+environment.apiEndPoints.inboundClaimLogs+'?key='+caseId).pipe(map(data=>{
      return data;
    }))
  }
  getClaimAlerts(page, itemsPerPage) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getClaimAlerts+"?page="+page+"&itemsPerPage="+itemsPerPage).pipe(map(data => {
      return data;
    }));
  }
  getInboundClaimAlerts(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getInboundClaimAlerts,data).pipe(map(data => {
      return data;
    }));
  }

  getCronData(page, itemsPerPage) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getCronData+"?page="+page+"&itemsPerPage="+itemsPerPage).pipe(map(data => {
      return data;
    }));
  }

  getCronDataById(id) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getCronDataById+"?_id="+id).pipe(map(data => {
      return data;
    }));
  }

  updatelogIdByClaim(travelCaseId) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.updatelogIdByClaim+"?travelCaseId="+travelCaseId).pipe(map(data => {
      return data;
    }));
  }

  
  getInvalidClaimAlerts(insuranceProvider: string, filterByKey: string, filterByValue: string,  fromDate) {
    if (filterByKey != "All") {
      filterByValue = filterByValue.trim()
    }
    
    let body: any = {};
    if (insuranceProvider)
    body.insuranceProvider = insuranceProvider;
    body.fromDate = fromDate;
    if (insuranceProvider == 'All')
    body.insuranceProvider = '';
    // body.page = page;
    // body.itemsPerPage = itemsPerPage;
    if (filterByKey == "ClaimId")
      body.caseNumber = filterByValue;
    if (filterByKey == "firstName")
      body.firstName = filterByValue;
    if (filterByKey == "lastName")
      body.lastName = filterByValue;
    if (filterByKey == "PolicyNumber")
      body.policyNumber = filterByValue;
    if (filterByKey == "certificateNumber")
      body.certificateNumber = filterByValue;
      
    // if (filterByKey == "Status")
    //   body.caseStatus = filterByValue;
    if (filterByKey == "Date")
      body.Date = filterByValue;
      if (filterByKey == "All") {
        body.Date = '';
        // body.caseStatus = '';
        body.certificateNumber = '';
        body.policyNumber = '';
        body.firstName = '';
        body.lastName = '';
        body.caseNumber = '';
        body.fromDate = '';
      }
    return this.http.post(this.baseUrl + environment.apiEndPoints.getInvalidClaimAlerts,body).pipe(map(data => {
      return data;
    }));
  }
  getRejectedClaimAlerts(insuranceProvider: string, filterByKey: string, filterByValue: string, fromDate) {
    if (filterByKey != "All") {
      filterByValue = filterByValue.trim()
    }
    
    let body: any = {};
    if (insuranceProvider)
    body.insuranceProvider = insuranceProvider;
    body.fromDate = fromDate;
    if (insuranceProvider == 'All')
    body.insuranceProvider = '';
    // body.page = page;
    // body.itemsPerPage = itemsPerPage;
    if (filterByKey == "ClaimId")
      body.caseNumber = filterByValue;
    if (filterByKey == "firstName")
      body.firstName = filterByValue;
    if (filterByKey == "lastName")
      body.lastName= filterByValue;
    if (filterByKey == "PolicyNumber")
      body.policyNumber = filterByValue;
    if (filterByKey == "certificateNumber")
      body.certificateNumber = filterByValue;
    if (filterByKey == "Date")
      body.Date = filterByValue;
      if (filterByKey == "All") {
        body.Date = '';
        // body.caseStatus = '';
        body.certificateNumber = '';
        body.policyNumber = '';
        body.firstName = '';
        body.lastName = '';
        body.caseNumber = '';
        body.fromDate = '';
      }
    return this.http.post(this.baseUrl + environment.apiEndPoints.getRejectedClaimAlerts,body).pipe(map(data => {
      return data;
    }));
  }

  getFlightDelays() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getFlightDelays).pipe(map(data => {
      return data;
    }));
  }

  getFlightCancels() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getFlightCancels).pipe(map(data => {
      return data;
    }));
  }
  
  getcheckdocument(data){
    return this.http.post(this.baseUrl+environment.apiEndPoints.checkdocument,data).pipe(map(data=>{
      return data;
    }))
  }

  getCheckLog(data){
    return this.http.post(this.baseUrl+environment.apiEndPoints.checkLogRecord,data).pipe(map(data=>{
      return data;
    }))
  }

  getCheckLogNew(data){
    return this.http.post(this.baseUrl+environment.apiEndPoints.checkLogRecordNew, data).pipe(map(data=>{
      return data;
    }))
  }

  updateClaimStatus(status: string, caseId: string,caseNumber:string) {
    let ClaimStatus = {
      "caseId": caseId,
      "status": status,
      "caseNumber":caseNumber
    }
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateClaimStatus, ClaimStatus).pipe(map(data => {
      return data;
    }));
  }

  updateCurrentClaimStatus(status: string, caseId: string ) {
    let ClaimStatus = {
      "travelCaseId": caseId,
      "status": status,
     
    }
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateCurrentClaimStatus, ClaimStatus).pipe(map(data => {
      return data;
    }));
  }

  getNextStatus(currentStatus: string, workFlowObjkey:string) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getNextFlowStatus + '?workFlowObjectKey=' + workFlowObjkey+'&status='+currentStatus).pipe(map(data => {
      return data;
    }));
  }

  uploadDocuments(file: File, casefileType: string, travelCaseId: string, docComment:string ) {
    if (file != undefined) {
      let formData: FormData = new FormData();
      //formData.append('Content-Disposition', file);
      formData.append('imgUploader', file);
      //formData.append('Content-Type', file.type);
      // console.log(casefileType, 'doc type id')
      return this.http.post(this.baseUrl + environment.apiEndPoints.uploadDocument + `?docTypeId=${casefileType}&travelCaseId=${travelCaseId}&docComment=${docComment}`, formData)
        .pipe(map(doc => {
          return doc;
        }));

    }
    else {
      window.alert("Please add a document to upload")
    }
  }

  uploadOPDDocuments(file: File, casefileType: string, travelCaseId: string, docComment:string, isPOD:boolean ) {
    if (file != undefined) {
      let formData: FormData = new FormData();
      //formData.append('Content-Disposition', file);
      formData.append('imgUploader', file);
      //formData.append('Content-Type', file.type);
      // console.log(casefileType, 'doc type id')
      return this.http.post(this.baseUrl + environment.apiEndPoints.uploadDocument + `?docTypeId=${casefileType}&travelCaseId=${travelCaseId}&docComment=${docComment}&isPOD=${isPOD}`, formData)
        .pipe(map(doc => {
          return doc;
        }));

    }
    else {
      window.alert("Please add a document to upload")
    }
  }

  uploadMultipleDocs(file: File) {
    if (file != undefined) {
      let formData: FormData = new FormData();
      formData.append('imgUploader', file);
      // console.log(formData, 'form data')
      let uploadarr = [
        {
          "docTypeId" : "INVOICE",
          "travelCaseId" : "608fe05d09839a31e87b67c7",
          "file" : "INVOICE"
        },
        {
          "docTypeId" : "PAN CARD",
          "travelCaseId" : "608fe05d09839a31e87b67c7",
          "file" : "INVOICE"
        }
      ]
      // console.log(uploadarr, 'upload array')
      return this.http.post(this.baseUrl + environment.apiEndPoints.uploadMultipleDocs, uploadarr)
        .pipe(map(doc => {
          return doc;
        }));
    }
    else {
      window.alert("Please add a document to upload")
    }
  }

  
  updateDocumentStatus(travelCaseId: string, docuemntId: string, status: string,caseNumber:string,docTypeId:string, documentName: string) {

    let updateDocumentStatus = {    "travelCaseId" : travelCaseId,
                                    "documentId" : docuemntId,
                                    "status":status,
                                    "caseNumber":caseNumber,
                                    "docTypeId":docTypeId,
                                    'documentName': documentName
                                  }

      return this.http.post(this.baseUrl + environment.apiEndPoints.UpdateDocumentStatus, updateDocumentStatus).pipe(map(response => {
        return response;
      }));
  }

  travelCaseUpdateDoc(documentName: string, casefileType: string, travelCaseId: string) {
    let travelCaseDocument = {
      "documentName": documentName,
      "docTypeId": casefileType,
      "travelCaseId": travelCaseId
    }
    return this.http.post(this.baseUrl + environment.apiEndPoints.travelCaseUpdateDoc, travelCaseDocument)
      .pipe(map(response => {
        return response;
      }));
  }
  travelCaseDeleteDoc(documentName: string,  travelCaseId: string, comment: string) {
    let travelCaseDocument = {
      "documentName": documentName,
      "travelCaseId": travelCaseId,
      "comment": comment
    }
    return this.http.post(this.baseUrl + environment.apiEndPoints.deleteDocument, travelCaseDocument)
      .pipe(map(response => {
        return response;
      }));
  }

  saveLog(logComment: string, logType: string, channel: string, direction: string, source:string, travelCaseId: string,loggedInUser:string) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveLogs, { comment: logComment, logType: logType,channel:channel,direction:direction,source:source, travelCaseId: travelCaseId,createdBy:loggedInUser}).pipe(map(data => {
      return data;
    }));
  }

  saveLogNew(logComment: string, logType: string, channel: string, direction: string, source:string, travelCaseId: string,loggedInUser:string, caseNumber:string, clientId:string, claimType:string, subClaimType:string, taskAssignee:string, logs_id:any) {
    // console.log(caseNumber, 'caseNumber')
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveLogsNewCollection, { comment: logComment, logType: logType,channel:channel,direction:direction,source:source, travelCaseId: travelCaseId, createdBy: loggedInUser, caseNumber: caseNumber, clientId:clientId, claimType:claimType, subClaimType:subClaimType, taskAssignee: taskAssignee, logs_id: logs_id}).pipe(map(data => {
      return data;
    }));
  }

  saveNote(logComment: string, travelCaseId: string, loggedInUser:string, caseNumber:string) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveNote, { comment: logComment, travelCaseId: travelCaseId,createdBy:loggedInUser, caseNumber: caseNumber}).pipe(map(data => {
      return data;
    }));
  }

  getLogs(page, itemsPerPage, travelCaseId) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getLogs+"?page="+page+"&itemsPerPage="+itemsPerPage+"&travelCaseId="+travelCaseId).pipe(map(data => {
      return data;
    }));
  }

  getNotes(page, itemsPerPage, travelCaseId) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getNotes+"?page="+page+"&itemsPerPage="+itemsPerPage+"&travelCaseId="+travelCaseId).pipe(map(data => {
      return data;
    }));
  }

  getMailIds(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getToEmails, data).pipe(map(data => {
      return data;
    }));
  }
  getGeneralMasterDataClientMultiple(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.clientWiseMasterData, data).pipe(map(data => {
      return data;
    }));
  }
 

  getClaimDetails(claimId){
    // console.log(claimId)
    let myp=new HttpParams();
    let ad=myp.set("claimId",claimId)
    // console.log('Hare ',ad);
    return this.http.get(this.baseUrl + environment.apiEndPoints.getClaimDetails,{params:ad}).pipe(map(data => {
      return data;
      
    }));
  }
  saveClaimDetails(data){
    // console.log(data)
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveClaimDetails, data).pipe(map(data => {
      return data;
      
    }));
  }

  uploadDocumentsService(file: File, casefileType: string, travelCaseId: string ) {
    if (file != undefined) {
      let formData: FormData = new FormData();
      formData.append('imgUploader', file);
      return this.http.post(this.baseUrl + environment.apiEndPoints.uploadDocument + `?docTypeId=${casefileType}&travelCaseId=${travelCaseId}`, formData)
        .pipe(map(doc => {
          return doc;
        }));
    }
    else {
      window.alert("Please add a document to upload")
    }
  }

 deleteClaimDetails(data){
  return this.http.post(this.baseUrl + environment.apiEndPoints.deleteClaimDetails,data).pipe(map(data => {
    return data;
  }));

 }
 updateClaimDetails(data){
  {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateClaimDetails,data).pipe(map(data => {
      return data;
    }));
  
   }
  }

  payableUpdate(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updatePayable,data).pipe(map(data => {
      return data;
    }));
  }

  saveNewNote(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveNewNote, data).pipe(map(data => {
      return data;
    }));
  }

  getNewNote(data) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getNewNote+"?_id="+data).pipe(map(data => {
      return data;
    }));
  }

  getFileTrackerById(caseId: string) {
    return this.http.get(this.baseUrl + environment.apiEndPoints.fileTrackerById + '?claimFileNumber=' + caseId).pipe(map(data => {
      return data;
    }));
  }

  updateFileTrackerData(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateFileTrackerData, data).pipe(map(data => {
      return data;
    }));
  }

  saveFileTrackerData(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.saveFileTrackerData, data).pipe(map(data => {
      return data;
    }));
  }

  getAllRGICDocs(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getAllRGICDocuments, data).pipe(map(data => {
      return data;
    }));
  }

  getDocByteStreamRGIC(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getDocumentByteStreamRGIC, data).pipe(map(data => {
      return data;
    }));
  }

  getRGICPolicyCopy(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getRGICPolicyCopy, data).pipe(map(data => {
      return data;
    }));
  }
  getCommunicationLetterFromRGIC(data) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.getRGICommunicationLetter, data).pipe(map(data => {
      return data;
    }));
  }

  getDocterDetails() {
    return this.http.get(this.baseUrl + environment.apiEndPoints.getDoctors).pipe(map(data => {
      return data;
    }));
  }

  getParticularDetail(data){
    return this.http.post(this.baseUrl + environment.apiEndPoints.searchDocter, data).pipe(map(data => {
      return data;
    }));
  }

  updateDoctor(client) {
    return this.http.post(this.baseUrl + environment.apiEndPoints.updateDoctor, client).pipe(map(data => {
      return data;
    }));
  }
  agentsClaimCount(){
    return this.http.get(this.baseUrl + environment.apiEndPoints.agentCase ).pipe(map(data => {
      return data;
    }));
  }
  
  getUnassignedCases(insuranceProvider: string, filterByKey: string, filterByValue: string, page, itemsPerPage, fromDate, userClaimMode: string){
    if (filterByKey != "All") {
      filterByValue = filterByValue.trim()
    }
    // console.log(userClaimMode);
    let body: any = {}; 
    if (insuranceProvider)
    body.insuranceProvider = insuranceProvider;
    body.fromDate = fromDate;
    body.page = page;
    body.itemsPerPage = itemsPerPage;
    body.userClaimMode = userClaimMode;
    if (insuranceProvider == 'All')
    body.insuranceProvider = '';
    body.page = page;
    body.itemsPerPage = itemsPerPage;
    if (filterByKey == "ClaimId")
      body.caseNumber = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
    if (filterByKey == "CustomerName")
      body.customerName = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
    if (filterByKey == "PolicyNumber")
      body.policyNumber = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
    if (filterByKey == "certificateNumber")
      body.certificateNumber = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
    if (filterByKey == "Status")
      body.caseStatus = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
    if (filterByKey == "Date")
      body.Date = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
      if (filterByKey == "All") {
        body.Date = '';
        body.caseStatus = '';
        body.certificateNumber = '';
        body.policyNumber = '';
        body.customerName = '';
        body.caseNumber = '';
        body.fromDate = '';
        body.page = page;
        body.itemsPerPage = itemsPerPage;
      }
      // console.log(body);
    
    return this.http.post(this.baseUrl + environment.apiEndPoints.getAllCases, body).pipe(map(data => {
      return data;
    }));
  }
  
  

  getCasesReassigned(insuranceProvider: string, filterByKey: string, filterByValue: string, page, itemsPerPage, fromDate,userClaimMode: string){
    if (filterByKey != "All") {
      filterByValue = filterByValue.trim()
    }
    
    let body: any = {};
    if (insuranceProvider)
    body.insuranceProvider = insuranceProvider;
    body.fromDate = fromDate;
    body.page = page;
    body.itemsPerPage = itemsPerPage;
    body.userClaimMode = userClaimMode;
    if (insuranceProvider == 'All')
    body.insuranceProvider = '';
    body.page = page;
    body.itemsPerPage = itemsPerPage;
    if (filterByKey == "ClaimId")
      body.caseNumber = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
    if (filterByKey == "CustomerName")
      body.customerName = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
    if (filterByKey == "PolicyNumber")
      body.policyNumber = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
    if (filterByKey == "certificateNumber")
      body.certificateNumber = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
    if (filterByKey == "Status")
      body.caseStatus = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
    if (filterByKey == "Date")
      body.Date = filterByValue;
      body.page = page;
      body.itemsPerPage = itemsPerPage;
            if (filterByKey == "All") {
        body.Date = '';
        body.caseStatus = '';
        body.certificateNumber = '';
        body.policyNumber = '';
        body.customerName = '';
        body.caseNumber = '';
        body.fromDate = '';
        body.page = page;
        body.itemsPerPage = itemsPerPage;
      }
    
    return this.http.post(this.baseUrl + environment.apiEndPoints.reAssingmentCases, body).pipe(map(data => {
      return data;
    }));
  }

  getAllOpenCases(){
    return this.http.get(this.baseUrl + environment.apiEndPoints.getAllOpenCases).pipe(map(data => {
      return data;
    }));
  }
  getNewOpenCases(body){
    return this.http.post(this.baseUrl + environment.apiEndPoints.getOpenCasesByPagination,body).pipe(map(data => {
      return data;
    }));
  }

  getAgentAssingedCases(body){
    return this.http.post(this.baseUrl + environment.apiEndPoints.agentAssingedCases,body).pipe(map(data => {
      return data;
    }));
  }

  getAgentAssingedCasePerPage(body){
    return this.http.post(this.baseUrl + environment.apiEndPoints.agentAssingedCasesPerPages,body).pipe(map(data => {
      return data;
    }));
  }
}
    
  
