import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import * as cryptoJS from 'crypto-js';
import * as jwt_decode from "jwt-decode";
import * as CryptoJS from 'crypto-js';
import { ActivatedRoute } from '@angular/router';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-loginsso',
  templateUrl: './loginsso.component.html',
  styleUrls: ['./loginsso.component.scss']
})
export class LoginssoComponent implements OnInit {
  userRole: any;
  ezToken: any;
  permission: any;

  permissions = {
    alerts: false,
    claimStatus_update: false,
    claim_history: false,
    create_claim: false,
    create_customer: false,
    customers_list: false,
    policy_search: false,
    update_claim: false,
    users: false,
    roles: false,
    eclaimsmenu: false,
    reports: false,
    ruleengine: false,
    instgra: false,
    Tasks: false,
    TaskList: false,
    task_notifications: false,
    insuranceProviders: false,
    plansAndSchemes: false,
    scheduleJob: false,
    generalMasters: false,
    documenttype: false,
    igMaster: false,
    channelMaster: false,
    communicationMaster: false,
    countries: false,
    masterData: false,
    igbuttons: false,
    bulkdataupload: false,
  };
  appName: any;
  clientIDE: any;
  cuserId: any;
  oAuthtoken: any;
  token: any;
  email: any;
  firstName: any;
  lastName: any;
  phoneNumber: any;
  redirectURL: any;
  clientID: any;
  userId: any;

  passwordHide: boolean = true;
  invalidotp: boolean = false;
  userName: string;
  password: string;
  isSignInClick = false;
  isLoginError = false;
  private webUrl = environment.webURL;
  logintrue: boolean = false;

  encdataToEncrypt: any = { id: "abc123", name: 'test' }
  encryptedData = "";
  secretKey = "mysecret"
  emailForm: FormGroup;
  sentmail: boolean = false
  signin: boolean = false
  otp: any;
  randomnumber: any;
  result: any;
  isUserAuthenticated: boolean;

  private _headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private httpClient: HttpClient, private route: ActivatedRoute, private authService: AuthService, private router: Router, private formBuilder: FormBuilder, private toastr: ToastrService) { }

  ngOnInit() {

    this.oAuthtoken = this.route.snapshot.queryParamMap.get('oAuthtoken');
    this.token = this.route.snapshot.queryParamMap.get('token');

    this.email = this.route.snapshot.queryParamMap.get('email');
    this.firstName = this.route.snapshot.queryParamMap.get('firstName');
    this.lastName = this.route.snapshot.queryParamMap.get('lastName');
    this.phoneNumber = this.route.snapshot.queryParamMap.get('phoneNumber');
    this.userId = this.route.snapshot.queryParamMap.get('userId');
    this.clientID = this.route.snapshot.queryParamMap.get('clientID');
    this.redirectURL = this.route.snapshot.queryParamMap.get('redirectURL');

    this.appName = this.route.snapshot.queryParamMap.get('appName');
    this.clientIDE = this.route.snapshot.queryParamMap.get('clientIDE');
    this.cuserId = this.route.snapshot.queryParamMap.get('userId');

    sessionStorage.setItem('oAuthtoken', this.token);

    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    })
    this.checkSSOSession();
  }

  public getToken(): string {
    let token = sessionStorage.getItem('token');
    return token;
  }

  public isAuthenticated(): boolean {
    console.log("came to authenticate...")
    let auth: boolean;
    const token = this.getToken();
    try {
      let tokeData = jwt_decode(token);
      let url = this.router.url;
      let user = url.split('/')[1];
      console.log(true);
      auth = true;
    } catch (e) {
      console.log("error decoding token");
      console.log(false);
      auth = false;
    }
    return auth;
  }

  onSignInClick() {
    this.isSignInClick = true;
    if (this.userName && this.password) {
      this.authService.signIn(this.userName, this.password)
        .subscribe((result: any) => {
          this.result = result;
          if (result != null && result.user != null) {
            this.signin = true;
            this.randomnumber = null
            this.randomnumber = Math.floor(Math.random() * (999999 - 100000)) + 100000;
            console.log(this.randomnumber);
            let form = {
              email: result.user.email,
              name: '',
              subject: "OTP for Login",
              body: "Your OTP to login is " + this.randomnumber
            }
            this.authService.sendMessage(form).subscribe();
          }
          // if (result != null && result.user != null) {
          //   localStorage.setItem('user_name', result.user.user_name);
          //   localStorage.setItem('_id', result.user._id);
          //   localStorage.setItem('token', result.user.token);
          //   localStorage.setItem('email', result.user.email);
          //   localStorage.setItem('role', result.user.role_name);
          //   localStorage.setItem('role_permissions', JSON.stringify(result.user.role_permissions));
          //   if(result.user.role_name == "admin"){
          //     this.logintrue = true;
          //     this.router.navigateByUrl('/admin/users')
          //   }else{
          //     this.logintrue = true;
          //     this.router.navigateByUrl('/agent/policy-search')
          //   }
          //   // if (returnUrl) {
          //   //   this.router.navigateByUrl(returnUrl);
          //   // } else {
          //   //  // alert("Hello");
          //   //   this.router.navigate(['/agent/policy-search']);
          //   // }
          // }
          // else {
          //   //alert("Invalid Credential");
          //   // var toastOptions = this.searchService.setToastOptions('Sign In Errors', 'Invalid Credential', '')
          //   // this.toastaService.error(toastOptions);
          // }

        }, err => {
          this.isLoginError = true;
        })

        ;
    }
    else {
      // var toastOptions = this.searchService.setToastOptions('Sign In Errors', 'Please enter username and password', '')
      // this.toastaService.error(toastOptions);
    }
  }

  checkOTP() {
    this.invalidotp = false;
    if (this.otp == this.randomnumber) {
      sessionStorage.setItem('user_name', this.result.user.user_name);
      sessionStorage.setItem('_id', this.result.user._id);
      sessionStorage.setItem('token', this.result.user.token);
      sessionStorage.setItem('email', this.result.user.email);
      sessionStorage.setItem('role', this.result.user.role_name);
      sessionStorage.setItem('role_permissions', JSON.stringify(this.result.user.role_permissions));
      if (this.result.user.role_name == "admin" || sessionStorage.getItem('role') == "superadmin") {
        this.logintrue = true;
        this.router.navigateByUrl('/admin/generalMasterReports')
      } else {
        this.logintrue = true;
        this.router.navigateByUrl('/agent/policy-search')
      }
    }
    else {
      console.log("invalid otp");
      this.invalidotp = true;
    }

  }

  back() {
    this.isSignInClick = false;
    this.userName = null;
    this.password = null;
    this.signin = false;
    this.result = null;
  }

  encrypt(value) {
    this.encryptedData = CryptoJS.AES.encrypt(JSON.stringify(this.encdataToEncrypt), this.secretKey).toString()
    console.log(this.encryptedData, 'encrypt')
  }
  decrypt() {
    let bytes = CryptoJS.AES.decrypt(this.encryptedData, this.secretKey)
    var obj = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    console.log(obj.id, 'id');
    console.log(obj.name, 'name');
  }
  getUserDetailsByEmail() {
    let email = this.emailForm.value.email
    console.log(email, 'email')
    this.authService.getUserDetailsByEmail(email).subscribe((data: any) => {
      if (data.length) {
        console.log(data[0], 'user data')
        let currenttime = new Date(data[0].currenttime).getTime();
        let link = this.webUrl + '/' + 'resetpassword/' + data[0]._id + '/' + currenttime
        let form = {
          email: data[0].email,
          name: '',
          subject: "Reset Password Link, This link will be expired in 30 minutes",
          body: link
        }
        this.authService.sendMessage(form).subscribe(res => {
          this.sentmail = true;
          this.emailForm.get('email').setValue('');
        })
      }
      else {
        this.toastr.warning("Account Does not Exist with this Email Address");
      }


    })
  }

  resetStage() {
    this.sentmail = false;
    this.emailForm.get('email').setValue('');
  }

  shoowPassword() {
    this.passwordHide = !this.passwordHide
  }

  checkSSOSession() {
    let user = {
      "clientId": this.clientID,
      "redirectUri": this.redirectURL,
      "userId": this.userId,
      "token": this.oAuthtoken
    }
    console.log(user, 'user for test')
    let encuser = {
      encryptedData: CryptoJS.AES.encrypt(
        JSON.stringify(user),
        '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'
      ).toString()
    };
    sessionStorage.setItem('validateCheck', JSON.stringify(encuser));
    this.authService.checkSSOSession(JSON.stringify(encuser)).subscribe((res: any) => {
      console.log(res, 'result')
      let body = '';
      if (res.code) {
        this.authService.getServerKey(body).subscribe((res: any) => {
          console.log(res, 'result')
          if (res.code == 200) {
            let bytes = CryptoJS.AES.decrypt(res.data, '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR');
            let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            console.log(decryptedData, 'data')

            this.authService.getTokenForSSo(decryptedData).subscribe((tok: any) => {
              console.log(tok, 'token token ')
              this.ezToken = tok.token
              console.log(this.ezToken, 'token ez')
              const permissionsdata = {
                "userId" : this.cuserId,
                "appName" : this.appName,
                "clientName" : this.clientIDE
              }
              console.log(permissionsdata, 'body for permissions')
              this.authService.getCommonPortalPermissions(permissionsdata).subscribe((data: any) => {
                console.log(data, 'permissions data ')
                let perms = data.data[0].roleData[0].pageData
                perms.forEach(element => {
                  if(element.pageName == 'Alerts') {
                    this.permissions.alerts = true
                  }
                  if(element.pageName == 'Claim Status Update') {
                    this.permissions.claimStatus_update = true
                  }
                  if(element.pageName == 'Claim History') {
                    this.permissions.claim_history = true
                  }
                  if(element.pageName == 'Create Claim') {
                    this.permissions.create_claim = true
                  }
                  if(element.pageName == 'Create Customer') {
                    this.permissions.create_customer = true
                  }
                  if(element.pageName == 'Customer List') {
                    this.permissions.customers_list = true
                  }
                  if(element.pageName == 'Policy Search') {
                    this.permissions.policy_search = true
                  }
                  if(element.pageName == 'Update Claim') {
                    this.permissions.update_claim = true
                  }
                  if(element.pageName == 'Users') {
                    this.permissions.users = true
                  }
                  if(element.pageName == 'Roles') {
                    this.permissions.roles = true
                  }
                  if(element.pageName == 'Eclaims Menu') {
                    this.permissions.eclaimsmenu = true
                  }
                  if(element.pageName == 'Reports') {
                    this.permissions.reports = true
                  }
                  if(element.pageName == 'Rule Engine') {
                    this.permissions.ruleengine = true
                  }
                  if(element.pageName == 'Instant Gratification') {
                    this.permissions.instgra = true
                  }
                  if(element.pageName == 'Tasks') {
                    this.permissions.Tasks = true
                  }
                  if(element.pageName == 'Task List') {
                    this.permissions.TaskList = true
                  }
                  if(element.pageName == 'Task Notifications') {
                    this.permissions.task_notifications = true
                  }
                  if(element.pageName == 'Insurance Providers') {
                    this.permissions.insuranceProviders = true
                  }
                  if(element.pageName == 'Plans And Schemes') {
                    this.permissions.plansAndSchemes = true
                  }
                  if(element.pageName == 'Schedule Job') {
                    this.permissions.scheduleJob = true
                  }
                  if(element.pageName == 'General Masters') {
                    this.permissions.generalMasters = true
                  }
                  if(element.pageName == 'Documents') {
                    this.permissions.documenttype = true
                  }
                  if(element.pageName == 'IG Master') {
                    this.permissions.igMaster = true
                  }
                  if(element.pageName == 'Channel Master') {
                    this.permissions.channelMaster = true
                  }
                  if(element.pageName == 'Communication Master') {
                    this.permissions.communicationMaster = true
                  }
                  if(element.pageName == 'Countries') {
                    this.permissions.countries = true
                  }
                  if(element.pageName == 'Master Data') {
                    this.permissions.masterData = true
                  }
                  if(element.pageName == 'igbuttons') {
                    this.permissions.igbuttons = true
                  }
                  if(element.pageName == 'Upload Bulk Data') {
                    this.permissions.bulkdataupload = true
                  }
                });
                this.userRole = data.data[0].roleData[0].role
                console.log(this.userRole, 'role data')

                this.authService.getCommonPortalPermissions(permissionsdata).subscribe((data: any) => {
                  console.log(data, 'permissions data ')
                  this.userRole = data.data[0].roleData[0].role
                  console.log(this.userRole, 'role data')
                  sessionStorage.setItem('token', this.ezToken);
                  let userBody = {
                    email: this.email,
                    role : this.userRole,
                    firstName: this.firstName,
                    lastName: this.lastName,
                    phoneNumber: this.phoneNumber
                  }

                  this.authService.getSSOUserDetails(userBody).subscribe((userData: any) => {
                    console.log('there is sso user in EzTravel')
                      console.log(userData, 'userData data ')
                      sessionStorage.setItem('user_name', userData.user_name);
                      sessionStorage.setItem('_id', userData._id);
                      sessionStorage.setItem('email', userData.email);
                      sessionStorage.setItem('role', userData.role);
                      sessionStorage.setItem('firstName', userData.fName);
                      sessionStorage.setItem('lastName', userData.lName);
                      sessionStorage.setItem('role_permissions', JSON.stringify(this.permissions));
  
                      if (this.isAuthenticated() != false) {
                        if (sessionStorage.getItem('role') == "admin" || sessionStorage.getItem('role') == "superadmin") {
                          this.logintrue = true;
                          this.router.navigateByUrl('/admin/generalMasterReports')
                        } else {
                          this.logintrue = true;
                          this.router.navigateByUrl('/agent/policy-search')
                        }
                      }
                      else {
                        console.log('stay in login')
                      }
                    
                  })
                  
                })

              })
            })


          }
        })
      }
    })
  }


}
